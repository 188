import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { closeCommend, openCommendChange, closeCommendChange } from "../../actions/commend.action";

class CommendComponent extends Component {
    state = {
        popupOpen: false
    };

    constructor(props) {
        super(props);

        this.onClickHandler = this.onClickHandler.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            popupOpen: props.displayCommend.open
        });
    }

    onClickHandler(e) {
        if (e.target.className === "popup") {
            this.props.closeCommend();
        }
    }

    render() {
        const popupClasses = classNames({
            popup: true,
            hide: !this.state.popupOpen
        });

        return (
            <div className={popupClasses} id="commend-popup" onClick={this.onClickHandler}>
                <div className="commend-content">
                    <div className="commend-section">
                        <img
                            src="https://media-exp2.licdn.com/mpr/mpr/shrinknp_400_400/AAIA_wDGAAAAAQAAAAAAAA0bAAAAJGVlMTMzMDAxLTA1OGQtNDg4Mi1hMTExLWFmNWQ5MDNkZGE1NQ.jpg"
                            alt="user-icon"
                        />
                        <textarea className="commend-textarea" placeholder="Add a comment" />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        displayCommend: state.commend
    };
}

export const Commend = connect(mapStateToProps, {
    openCommendChange,
    closeCommendChange,
    closeCommend
})(CommendComponent);
