const EMAIL_REGR = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function checkIsObj(str) {
    const strTrim = str.trim();
    if (strTrim[0] === "{" && strTrim[strTrim.length - 1] === "}") {
        return "object";
    } else if (strTrim[0] === "[" && strTrim[strTrim.length - 1] === "]") {
        return "array";
    }
    return null;
}

export function convertToStringObj(str) {
    let txt = str;
    // a regex to search for all the keys in an object string
    const objKeysRegex = /({|,)(?:\s*)(?:')?([A-Za-z_$.][A-Za-z0-9_ \-.$]*)(?:')?(?:\s*):/g;
    // convert all keys to quoted keys
    txt = txt.replace(objKeysRegex, "$1\"$2\":");
    if (checkIsObj(txt)) {
        txt = txt.replace(/ /g, "");
    }
    return txt;
}

export function stringToProperCase(str) {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export function isValidEmail(email) {
    // regex from http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
    const re = EMAIL_REGR;
    return re.test(email);
}
