import axios from "axios";
import { API_URL } from "../config";
import { getCookie } from "../utils/cookie.util";

export const ON_DONE_LOADING_QUESTION = "ON_DONE_LOADING_QUESTION";
export const ON_LOADING_QUESTION = "ON_LOADING_QUESTION";

export const onDoneLoadingQuestion = values => ({ type: ON_DONE_LOADING_QUESTION, values });
export const onLoadingQuestion = values => ({ type: ON_LOADING_QUESTION, values });

export const loadQuestion = (id) => (
    (dispatch) => {
        dispatch(onLoadingQuestion({
            questionObj: null,
            isLoadingQuestion: true
        }));

        axios.get(`${API_URL}/api/question/${id}`, {
            headers: { Authorization: `${getCookie("authorize")}` }
        })
        .then(res => {
            let question = {};

            if (res.data && res.data.data && res.data.data.length) {
                const { data } = res.data;

                for(let i = 0, l = data.length; i < l; i++) {
                    question = data[i];

                    question.params = JSON.parse(question.params || '[]');
                    question.returnType = JSON.parse(question.returnType || '{}');
                    question.moreParamInfo = question.moreParamInfo || '';
                    question.solutionCode = question.solutionCode || '';
                    question.is_published = question.is_published;
                }

                dispatch(onDoneLoadingQuestion({
                    questionObj: data[0],
                    isLoadingQuestion: false 
                }));
            }

            // add error handling code

        }).catch(err => {
            if (err) {}
        });
    }
);
