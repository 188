import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import axios from "axios";
import { Link } from "react-router-dom";

import { API_URL } from "../config";

import "./privacy.scss";

class Privacy extends Component {
    state = {
    	privacyHtmlStr: ""
    }

    componentDidMount() {
		axios.get(`${API_URL}/api/privacy`)
		.then(res => {
			this.setState({
				privacyHtmlStr: res.data
			})

        }).catch(err => {
            if (err) {}
        });
    }

    render() {
        return (
            <div className="privacy-container with-font-smoothing" dangerouslySetInnerHTML={{__html: this.state.privacyHtmlStr}}>
            </div>
        );
    }
}

const mapStoreToProps = store => ({});

export default connect(mapStoreToProps, {})(Privacy);
