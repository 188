import { SHOW_COMMEND, CLOSE_COMMEND } from "../actions/commend.action";

const initialState = {
    open: false
};

export function commendReducer(state = initialState, action) {
    switch (action.type) {
    case SHOW_COMMEND: {
        return { ...state, open: action.payload.open };
    }
    case CLOSE_COMMEND: {
        return { ...state, open: action.payload.open };
    }
    default:
        return state;
    }
}
