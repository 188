export const questionCategoryIdHash = {
	id_1680: "Javascript",
    id_1681: "Array and String",
    id_1682: "Dynamic Programming",
    id_1683: "Tree",
    id_1684: "Graph",
    id_1685: "Primitive Type",
    id_1686: "Big O and Time Complexity",
    id_1687: "Array",
    id_1688: "String",
    id_1689: "Stack",
    id_1690: "Recursion",
    id_1691: "Searching",
    id_1692: "Sorting",
    id_1693: "Linked List"
};

export const sortedQuestionCategories = [
    'id_1687', // Array
    'id_1688', // String
    'id_1683', // Tree
    'id_1693', // Linked List
    'id_1684', // Graph
    'id_1690', // Recursion
    'id_1685', // Primitive Type
    'id_1691', // Searching
    'id_1682', // Dynamic Programming
    'id_1692', // Sorting
    'id_1689' // Stack
];

export const dropdownOptions = [
    {
        value: "id_1680",
        name: "Javascript"
    },
    {
        value: "id_1681",
        name: "Array and String"
    },
    {
        value: "id_1682",
        name: "Dynamic Programming"
    },
    {
        value: "id_1683",
        name: "Tree"
    },
    {
        value: "id_1684",
        name: "Graph"
    },
    {
        value: "id_1685",
        name: "Primitive Type"
    },
    {
        value: "id_1686",
        name: "Big O and Time Complexity"
    },
    {
        value: "id_1687",
        name: "Array"
    },
    {
        value: "id_1688",
        name: "String"
    },
    {
        value: "id_1689",
        name: "Stack"
    },
    {
        value: "id_1690",
        name: "Recursion"
    },
    {
        value: "id_1691",
        name: "Searching"
    },
    {
        value: "id_1692",
        name: "Sorting"
    },
    {
        value: "id_1693",
        name: "Linked List"
    }
];
