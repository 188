import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import _ from "underscore";
import { Alert, FormGroup, FormControl } from "react-bootstrap";
import { API_URL } from "../config";
import { debounce } from "../utils/helper.util";

import "./login-signup-reset.scss";
import "./common/brand.scss";
import "./common/button.scss";

const VALIDATION_DELAY = 700;

class ResetPasswordConfirm extends Component {
    state = {
        password: "",
        confirmPassword: "",
        hideForm: false,
        showMessage: false,
        showPasswordMessage: false,
        showPasswordWarningMessage: false,
        showPasswordLetterValid: false,
        showPasswordNumberValid: false,
        showPasswordCharLengthValid: false,
        showConfirmPasswordMessage: false,
        confirmPasswordMessage: "",
        hasSecurePassword: false,
        enableImageFader: false,
        message: '',
        responseMessage: ''
    }

    componentDidMount() {
        if (this.props.authorize) {
            this.props.history.push("/");
        }

        setTimeout(() => {
            this.setState({
                enableImageFader: true
            });
        }, 100);

        this.getConfirmPasswordValidationStateDebounce = debounce(this.getConfirmPasswordValidationState, VALIDATION_DELAY);
    }

    handleDismiss = () => {
        this.setState({
            message: ''
        });
    }

    handlePasswordInput = (event) => {
        const password = event.target.value || "";

        this.setState({
            password
        });

        this.getPasswordValidationState(password);
    }

    handlePasswordInputOnBlur = (event) => {
        const password = event.target.value || "";

        if (!password.length) {
            this.setState({
                showPasswordMessage: false
            });
        } else {
            this.getPasswordValidationState(password);
        }
    }

    getPasswordValidationState = (password) => {
        let validationResult = {};

        validationResult = this.validatePassword(password);

        this.setState({
            showPasswordMessage: true,
            showPasswordLetterValid: validationResult.hasCapitalLetter,
            showPasswordNumberValid: validationResult.hasNumber,
            showPasswordCharLengthValid: validationResult.hasMinChars,
            hasSecurePassword: validationResult.isAllValid,
            showPasswordWarningMessage: validationResult.isNotEmpty &&
                !validationResult.isAllValid &&
                (validationResult.hasMinChars || validationResult.hasCapitalLetter || validationResult.hasNumber)
        });
    }

    handleConfirmPasswordInput = (event) => {
        const confirmPassword = event.target.value || "";

        this.setState({
            confirmPassword
        });

        this.getConfirmPasswordValidationStateDebounce(confirmPassword);
    }

    handleConfirmPasswordInputOnBlur = (event) => {
        const confirmPassword = event.target.value || "";

        this.getConfirmPasswordValidationState(confirmPassword);
    }

    getConfirmPasswordValidationState = (confirmPassword) => {
        const length = confirmPassword.length;
        const password = this.state.password;
        let showMessage = false;
        let message = "";

        if (!password.length) {
            showMessage = true;
            message = "Please create a password";
        } else if (!length) {
            showMessage = true;
            message = "Please confirm your password";
        } else if (this.isValidConfirmPassword(confirmPassword)) {
            showMessage = false;
            message = "";
        } else {
            showMessage = true;
            message = "Passwords don't match";
        }

        this.setState({
            showConfirmPasswordMessage: showMessage,
            confirmPasswordMessage: message
        });
    }

    isValidPassword = (password) => this.validatePassword(password).isAllValid;

    isValidConfirmPassword = (confirmPassword) => confirmPassword === this.state.password;

    validatePassword = (password) => {
        const length = password.length;
        const isNotEmpty = length > 0;
        const hasMinChars = length >= 8;
        const hasCapitalLetter = password.replace(/[^a-z]/ig, "").length > 0;
        const hasNumber = /\d/.test(password);
        const isAllValid = isNotEmpty && hasMinChars && hasCapitalLetter && hasNumber;

        return {
            isNotEmpty,
            hasMinChars,
            hasCapitalLetter,
            hasNumber,
            isAllValid
        };
    }

    submitAndContinue = (event) => {
        event.preventDefault();

        const canProceed = this.isValidPassword(this.state.password)
            && this.isValidConfirmPassword(this.state.confirmPassword);
        debugger
        if (canProceed) {
            const data = {
                password: this.state.password,
                token: this.props.match.params.token
            };

            window
                .fetch(`${API_URL}/api/reset-password-confirm`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                })
                .then(res => {
                    return res.json();
                })
                .then(data => {
                    if (data.success) {
                        this.setState({
                            hideForm: true,
                            responseMessage: data.message
                        });
                    } else {
                        this.setState({
                            hideForm: false,
                            responseMessage: data.message
                        });
                    }
                });
        } else {
            this.getConfirmPasswordValidationState(this.state.confirmPassword);
        }
    }

    render() {
        const hideClass = classNames({
            hide: this.state.hideForm
        });

        const emailTakenClasses = classNames({
            "top-error-box": true,
        });
        const showEmailTakenMsg = !!this.state.message;

        const sideImageClass = classNames({
            "side-image": true,
            "image-fader": this.state.enableImageFader
        });

        const showPasswordMessage = !!this.state.showPasswordMessage;

        const passwordStateClass = classNames({
            "password-group": true,
            "secure-password": this.state.hasSecurePassword,
            "has-warning": this.state.showPasswordWarningMessage
        });

        const passwordLetterClass = classNames({
            "password-letter": true,
            "is-valid": !!this.state.showPasswordLetterValid
        });

        const passwordNumberClass = classNames({
            "password-number": true,
            "is-valid": !!this.state.showPasswordNumberValid
        });

        const passwordCharLengthClass = classNames({
            "password-char-length": true,
            "is-valid": !!this.state.showPasswordCharLengthValid
        });

        const showConfirmPasswordMessage = !!this.state.showConfirmPasswordMessage;

        const responseMessageClass = classNames({
            "message-container": true,
            error: !this.state.hideForm
        });

        return (
            <div className="reset-password-confirm-container with-font-smoothing">
                <div className="brand-picture">
                    <div className={sideImageClass} />
                </div>
                <div className="reset-password-confirm-box">
                    <div className="brand-container-wrapper">
                        <div className="brand-container">
                            <a href="/">
                                <div className="logo" />
                                <div className="name">
                                    <h1>JSCRUNCH</h1>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="title">
                        <h1>Reset Password</h1>
                    </div>

                    <div className={responseMessageClass}>{this.state.responseMessage}</div>

                    <div className={`create_account_form ${hideClass}`}>
                        <form onSubmit={this.submitAndContinue}>
                            <Alert show={showEmailTakenMsg} className={emailTakenClasses} closeLabel="" variant="danger" onClose={this.handleDismiss} dismissible>
                                {this.state.message}
                            </Alert>
                            <div className={passwordStateClass}>
                                <FormGroup controlId="password">
                                    <FormControl
                                        type="password"
                                        bsSize="large"
                                        value={this.state.password}
                                        placeholder="Create a password"
                                        onChange={this.handlePasswordInput}
                                        onFocus={this.handlePasswordInput}
                                        onBlur={this.handlePasswordInputOnBlur}
                                    />
                                    <Alert show={showPasswordMessage} className="password-message" variant="danger">
                                        {this.state.hasSecurePassword ? (
                                            <span>Now, that's a secure password</span>
                                        ) : (
                                            <span>
                                                Password must contain a <strong className={passwordLetterClass}>letter</strong> and a <strong className={passwordNumberClass}>number</strong>
                                                , and be minimum of <strong className={passwordCharLengthClass}>8 characters</strong>
                                            </span>
                                        )}
                                    </Alert>
                                </FormGroup>
                            </div>

                            <FormGroup controlId="confirmPassword">
                                <FormControl
                                    type="password"
                                    bsSize="large"
                                    value={this.state.confirmPassword}
                                    placeholder="Confirm password"
                                    onChange={this.handleConfirmPasswordInput}
                                    onBlur={this.handleConfirmPasswordInputOnBlur}
                                />
                                <Alert show={showConfirmPasswordMessage} className="confirm-password-message" variant="danger">
                                    {this.state.confirmPasswordMessage}
                                </Alert>
                            </FormGroup>

                            <button type="submit" className="button button_wide">
                                Reset password
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStoreToProps = store => ({
    authorize: store.login.authorize
});

export default connect(mapStoreToProps, {})(ResetPasswordConfirm);
