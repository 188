import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "./privacyAndTerm.scss";

class PrivacyAndTermComponent extends Component {
    render() {
        let withCopyright = this.props.withCopyright;

        return (
            <div className="privacyAndTermContainer with-font-smoothing">
                {withCopyright ? "Copyright © 2020 " : ""}
                JSCRUNCH {" "}
                <Link className="link" to="/about">About</Link>{" "}
                <Link className="link" to="/privacy">Privacy</Link>{" "}
                <Link className="link" to="/term">Terms</Link>
            </div>
        );
    }
}

function mapStoreToProps(store) {
    return {};
}

export const PrivacyAndTerm = connect(mapStoreToProps, {})(PrivacyAndTermComponent);
