import { ON_DONE_CHANGING_PROFILE,
	ON_CHANGING_PROFILE,
	ON_DONE_CHANGING_PASSWORD,
	ON_CHANGING_PASSWORD
} from "../actions/account.action";

const INITIAL_STATE = {
	changedProfileMessage: '',
	changedProfileSuccess: false,
    isChangingProfile: false,
	changedPasswordMessage: '',
	changedPasswordSuccess: false,
    isChangingPassword: false
};

export function accountReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
	    case ON_CHANGING_PROFILE:
	        return { ...state, ...action.values };
	    case ON_DONE_CHANGING_PROFILE:
	        return { ...state, ...action.values };
	    case ON_CHANGING_PASSWORD:
	        return { ...state, ...action.values };
	    case ON_DONE_CHANGING_PASSWORD:
	        return { ...state, ...action.values };
	    default:
	        return state;
    }
}
