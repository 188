import React, { Component } from "react";
import { connect } from "react-redux";
import { Nav, NavItem, Navbar } from "react-bootstrap";
import { LockFill } from 'react-bootstrap-icons';

import { Link } from "react-router-dom";
import Icon from "../signup/icon.react";
import { logOut } from "../../actions/login.action";
import { openCommend } from "../../actions/commend.action";
import { questionsQuery } from "../../actions/questions.action";
import { loadQuestion } from "../../actions/question.action";
import { setActiveQuestion } from "../../actions/sideBar.action";
import { questionCategoryIdHash, sortedQuestionCategories } from "../../configs/common.config";

import { PrivacyAndTerm } from "./privacyAndTerm.react";

import "./sideBar.scss";

class SideBarBase extends Component {
    state = {
        activeId: '',
        closeDropdown: {},
    };

    componentDidMount() {
        this.props.questionsQuery({simple: true});
    }

    componentWillReceiveProps(props) {
        if (props.sideBar && props.sideBar.activeId) {
            this.setActive(props.sideBar && props.sideBar.activeId);
            // this.props.questionsQuery({simple: true});
            // console.log("****** sent questionsQuery");
        }
    }

    onClick = (questionId) => {
        if (questionId === "dashboard") {
            this.props.history.push('/app');
            this.props.setActiveQuestion("");
        } else if (questionId) {
            if (this.props.location.pathname !== `/question/${questionId}`) {
                this.props.loadQuestion(questionId);
                this.props.history.push(`/app/question/${questionId}`);
            }

            this.props.setActiveQuestion(questionId);
        }
    }

    onClickQuestionCat = (event, catKey) => {
        let newCloseDropdown = Object.assign({}, this.state.closeDropdown);

        if (newCloseDropdown.hasOwnProperty(catKey)) {
            newCloseDropdown[catKey] = !newCloseDropdown[catKey];
        } else {
            newCloseDropdown[catKey] = true;
        }
        this.setState({
            closeDropdown: newCloseDropdown
        })
    }

    setActive = (id) => {
        // set active element
        this.setState({activeId: id});
    }

    componentWillUnmount = () => {
        this.props.setActiveQuestion("");
    }

    render() {
        let className = "";
        const { props: { location: { pathname }, sideBar, questions }, onClick, onClickQuestionCat } = this;

        return (
            <div id="sidebar-menu" className="sideBarMenuContainer with-font-smoothing">
                <div className="sidebar navbar navbar-default">
                    <Nav className="nav navbar-nav" as="ul">
                        <Nav.Item className={!this.state.activeId ? "title active" : "title"} key="title" onClick={() => onClick("dashboard")} as="li">
                            <a>Dashboard</a>
                        </Nav.Item>
                    </Nav>

                    {
                        (questions.questionsCategory ?
                            sortedQuestionCategories.map(catKey => {
                                let ulClass = this.state.closeDropdown[catKey] ? "nav navbar-nav close-dropdown"  : "nav navbar-nav";

                                if (!questions.questionsCategory[catKey]) {
                                    return null;
                                }

                                return (
                                    <Nav className={ulClass} as="ul">
                                        <Nav.Item className="question-category" key={catKey} onClick={(event) => onClickQuestionCat(event, catKey)} as="li">
                                            <a>
                                                <span className="list-question-title">{questionCategoryIdHash[catKey]}</span>
                                                <i className="dropdown-up">
                                                    <Icon type="dropdown-up" />
                                                </i>
                                            </a>
                                        </Nav.Item>

                                        {
                                            questions.questionsCategory[catKey].map(question => {
                                                const isLocked = !question.isEnabled;
                                                const className = "question " + (question.id === this.state.activeId ? 'active ' : '') +
                                                    (isLocked ? "is-locked" : "");
                                                const iconContainerClass = "icon-container " + (question.result == "1" ? "pass" :
                                                        question.result == "0" ? "fail" : "");

                                                return (
                                                    <Nav.Item className={className} key={question.id} onClick={() => onClick(question.id)} as="li">
                                                        <a>
                                                            <div className="question-item">
                                                                <div className={iconContainerClass}>
                                                                    <div className="upper-vertical">
                                                                    </div>
                                                                    <div className="circle-icon-container">
                                                                    </div>
                                                                    <div className="lower-vertical">
                                                                    </div>
                                                                </div>
                                                                <div className="question-title">
                                                                    {isLocked ? <LockFill className="lock-icon" size="17px" /> : null}
                                                                    {question.question}
                                                                </div>
                                                            </div>
                                                            <div className="comment-image" onClick={() => this.props.openCommend()}>
                                                                <button>Comment</button>
                                                            </div>
                                                        </a>
                                                    </Nav.Item>
                                                )
                                            })
                                        }
                                    </Nav>)
                            }) : null)
                    }

                </div>
                <PrivacyAndTerm />
            </div>
        );
    }
}

function mapStoreToProps(store) {
    return {
        sideBar: store.sideBar,
        questions: store.questions
    };
}

export const SideBar = connect(mapStoreToProps, {
    logOut,
    questionsQuery,
    openCommend,
    loadQuestion,
    setActiveQuestion
})(SideBarBase);
