import { combineReducers } from "redux";
import {
    codeEditorReducer,
    sideBarReducer,
    commendReducer,
    signupReducer,
    loginReducer,
    questionsReducer,
    questionRunTestReducer,
    questionReducer,
    accountReducer
} from "./reducers";

const reducers = combineReducers({
    codeEditor: codeEditorReducer,
    sideBar: sideBarReducer,
    commend: commendReducer,
    signup: signupReducer,
    login: loginReducer,
    questions: questionsReducer,
    questionRunTest: questionRunTestReducer,
    questionObj: questionReducer,
    account: accountReducer
});

export default reducers;
