export const SHOW_COMMEND = "SHOW_COMMEND";
export const CLOSE_COMMEND = "CLOSE_COMMEND";

export const openCommendChange = () => ({ type: SHOW_COMMEND, payload: { open: true } });
export const closeCommendChange = () => ({ type: CLOSE_COMMEND, payload: { open: false } });

export const openCommend = () => dispatch => {
    dispatch(openCommendChange());
};

export const closeCommend = () => dispatch => {
    dispatch(closeCommendChange());
};
