import axios from "axios";
import { API_URL } from "../config";
import { getCookie } from "../utils/cookie.util";

export const ON_QUESTIONS_CHANGE = "ON_QUESTIONS_CHANGE";
export const ON_QUESTION_SAVE = "ON_QUESTION_SAVE";

export const onQuestionsChange = values => ({ type: ON_QUESTIONS_CHANGE, values });

export const questionsQuery = (params={}) => (
    (dispatch) => {
        axios.get(`${API_URL}/api/questions`, {
            params,
            headers: { 'Authorization': `${getCookie("authorize")}` }
        })
        .then(res => {
            let question = {};
            let questions = [];
            let crunchCompletedQuestions = 0;
            let fullCompletedQuestions = 0;
            let crunchNotCompletedQuestions = 0;
            let fullNotCompletedQuestions = 0;

            if (res.data && res.data.data && res.data.data.questions) {
                const { questions } = res.data.data;
                let questionsObj = {};

                for(let i = 0, l = questions.length; i < l; i++) {
                    question = questions[i];
                    questionsObj[question.id] = question;

                    question.params = JSON.parse(question.params || '[]');
                    question.returnType = JSON.parse(question.returnType || '{}');
                    question.solutionCode = question.solutionCode || '';
                    question.is_published = question.is_published;
                    if (question.result) {
                        if (question.course_plan === "full") {
                            fullCompletedQuestions++;
                        } else if (question.course_plan === "crunch") {
                            fullCompletedQuestions++;
                            crunchCompletedQuestions++;
                        }
                    } else {
                        if (question.course_plan === "full") {
                            fullNotCompletedQuestions++;
                        } else if (question.course_plan === "crunch") {
                            fullNotCompletedQuestions++;
                            crunchNotCompletedQuestions++;
                        }
                    }

                }

                let sortedQuestions = questions.sort(function(a,b){
                    if (a.questionNumber && b.questionNumber) {
                        return parseInt(a.questionNumber) - parseInt(b.questionNumber);
                    } else if (a.questionNumber) {
                        return 1;
                    } else {
                        return -1;
                    }
                });

                dispatch(onQuestionsChange({
                    questions: sortedQuestions,
                    questionsObj,
                    questionsCategory: res.data.data.questionsCategory,
                    crunchCompletedQuestions,
                    fullCompletedQuestions,
                    crunchNotCompletedQuestions,
                    fullNotCompletedQuestions
                }));
            }
        }).catch(err => {
            if (err) {}
        });
    }
);
