import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import axios from "axios";
import { Button, Alert, FormGroup, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";

import { API_URL } from "../config";

import "./term.scss";

class Term extends Component {
    state = {
    	termHtmlStr: ""
    }

    componentDidMount() {
		axios.get(`${API_URL}/api/term`)
		.then(res => {
			this.setState({
				termHtmlStr: res.data
			})

        }).catch(err => {
            if (err) {}
        });
    }

    render() {
        return (
            <div className="term-container with-font-smoothing" dangerouslySetInnerHTML={{__html: this.state.termHtmlStr}}>
            </div>
        );
    }
}

const mapStoreToProps = store => ({});

export default connect(mapStoreToProps, {})(Term);
