import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { getCookie } from "../../utils/cookie.util";
import { stringToProperCase } from "../../utils/helper.util";
import "./profile.scss";

class ProfileComponent extends Component {
    componentDidMount() {
        document.addEventListener("click", this.handleClickOutside, true); // eslint-disable-line
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this); // eslint-disable-line

        if (domNode) {
            if (this.profileContainer.contains(event.target)) {
                return;
            } else {
                this.props.updateSetting(false);
            }
        } else {
            this.props.updateSetting(false);
        }
    };

    handleClickInside = event => {
        if ( (!this.props.showSetting && this.profileContainer.contains(event.target)) ||
            (this.props.showSetting && !this.dropdownMenu.contains(event.target)) ) {
            this.props.updateSetting(!this.props.showSetting);
        }
    };

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside, true); // eslint-disable-line
    }

    render() {
        const {handleClickInside} = this;
        const { showSetting, user, handleSelect, authorize } = this.props;
        const userName = user ? stringToProperCase(user.name || "") : "";
        const isShow = showSetting ? 'show' : '';
        const dropDownClasses = `setting-dropdown ${isShow}`;

        return (
            <div className="profile-container" onClick={handleClickInside} ref={(el) => {this.profileContainer = el}}>
                <div className="profile-button">
                    <span className="username">{userName[0]}</span>
                </div>
                <div className={dropDownClasses} ref={(el) => {this.settingDropdown = el}}>
                    <ul className="dropdown-menu"  ref={(el) => {this.dropdownMenu = el}}>
                        <li className="dropdown-header">
                            <div className="upper">Signed in as</div>
                            <strong>{userName}</strong>
                        </li>
                        <li className="divider" />
                        <li eventKey="account" onClick={() => {handleSelect("account")}}>
                            <a>My Account</a>
                        </li>
                        <li eventKey="logout" onClick={() => {handleSelect("logout")}}>
                            <a>Sign Out</a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authorize: state.login.authorize,
        user: state.login.user || {}
    };
}

export const Profile = connect(mapStateToProps, {})(ProfileComponent);
