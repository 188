import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';

import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from 'react-redux';
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";

import reducers from "./mainReducers";
import Routes from "./routes";

import { onLoginSubmitted } from "./actions/login.action";
import { getCookie } from "./utils/cookie.util";

const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));
const token = getCookie("authorize");

// if (token) {
//     store.dispatch(onLoginSubmitted({ authorize: true }));
// }

// code mirror initialization
if (typeof window !== "undefined" && typeof window.navigator !== "undefined") {
    require("codemirror/mode/javascript/javascript");
    require("codemirror/addon/lint/lint");
    require("codemirror/addon/selection/active-line");
    require("codemirror/addon/selection/mark-selection.js");

    require("codemirror/addon/lint/javascript-lint");
    require("codemirror/addon/edit/closebrackets");
    require("codemirror/addon/edit/closetag");
    require("codemirror/addon/hint/show-hint.js");
    require("codemirror/addon/hint/anyword-hint.js");
    // require("codemirror/addon/hint/javascript-hint.js");
    require("../src/third-party/codemirror/javascript-hint.js");

    // CSS file
    require("codemirror/lib/codemirror.css");
    require("codemirror/addon/hint/show-hint.css");
}

ReactDOM.render(
  	<React.StrictMode>
  		<Provider store={store}>
    		<Router>
          <Route path="/" component={Routes} />
    		</Router>
  		</Provider>
  	</React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();