import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import CodeMirror from "react-codemirror";
import ReactQuill from "react-quill";
import axios from "axios";
import { API_URL } from "../../config";
import { getCookie } from "../../utils/cookie.util";

import "./solutionModal.scss";

const defaultState = {
    solution: "",
    hasRequestedSolution: false
};

export class SolutionModal extends Component {
    state = { 
        solution: "",
        hasRequestedSolution: false
    };

    handleClose = () => {
        const { updateState } = this.props;
        this.setState(defaultState);
        updateState({ showSolutionModal: false });
    };

    componentDidUpdate() {
        if (this.props.showModal && !this.state.hasRequestedSolution) {
            this.setState({
                hasRequestedSolution: true
            });

            let id = this.props.questionId;

            axios.get(`${API_URL}/api/question/solution`, {
                params: {
                    question_id: id
                },
                headers: { Authorization: `${getCookie("authorize")}` }
            })
            .then(res => {
                let question = {};

                if (res.data && res.data.data) {
                    const solution = res.data.data;

                    this.setState({
                        solution
                    })
                }
                // add error handling code

            }).catch(err => {
                if (err) {}
            });
        }
    }

    render() {
        const {
            props: {
                showModal
            },
            state: {
                solution
            },
            handleClose
        } = this;

        return (
            <Modal className="solution-modal" show={showModal} onHide={handleClose}>
                <div>
                	<Modal.Header closeButton>
                    	<Modal.Title>Solution</Modal.Title>
	                </Modal.Header>
	                <Modal.Body>
	                    <ReactQuill
                            theme="snow"
                            modules={{toolbar:false}}
                            readOnly={true}
                            value={solution ? solution : null}
                        />
	                </Modal.Body>
                </div>
            </Modal>
        );
    }
}
