import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Alert, FormGroup, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import _ from "underscore";
import classNames from "classnames";
import { API_URL } from "../config";
import { isValidEmail } from "../utils/helper.util";

import "./login-signup-reset.scss";
import "./common/brand.scss";
import "./common/button.scss";

class ResetPassword extends Component {
    state = {
        email: "",
        message: "",
        hasError: false,
        hideForm: false,
        enableImageFader: false,
        responseMessage: ""
    }

    componentDidMount() {
        if (this.props.authorize) {
            this.props.history.push("/");
        }

        setTimeout(() => {
            this.setState({
                enableImageFader: true
            });
        }, 100);
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.authorize) {
    //         this.props.history.push("/");
    //     }

    //     if (nextProps.resetPasswordStore.success) {
    //         this.setState({
    //             hideForm: true,
    //             responseMessage: nextProps.resetPasswordStore.message
    //         });
    //     } else {
    //         this.setState({
    //             hideForm: false,
    //             responseMessage: nextProps.resetPasswordStore.message
    //         });
    //     }
    // }

    handleEmailInput = (event) => {
        this.setState({
            email: event.target.value
        });
    }

    isEmpty = (value) => !_.isEmpty(value);

    submitAndContinue = (event) => {
        event.preventDefault();

        const email = this.state.email;
        const emailIsValid = isValidEmail(email);

        if (emailIsValid) {
            const data = {
                email
            };
            debugger
            window
                .fetch(`${API_URL}/api/reset-password`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                })
                .then(res => {
                    debugger
                    return res.json();
                })
                .then(data => {
                    debugger
                    if (data.success) {
                        this.setState({
                            hideForm: true,
                            responseMessage: data.message
                        });
                    } else {
                        this.setState({
                            hideForm: false,
                            responseMessage: data.message
                        });
                    }
                });
        } else {
            let message = "";

            if (!email.length) {
                message = "Email address cannot be empty";
            } else {
                message = "Email is invalid";
            }

            this.setState({
                hasError: true,
                message
            });
        }
    }

    handleDismiss = () => {
        this.setState({
            hasError: false,
            message: ""
        });
    }

    render() {
        const hideClass = classNames({
            hide: this.state.hideForm
        });

        const errorClass = classNames({
            "top-error-box": true
        });
        const showErrMsg = this.state.hasError;

        const sideImageClass = classNames({
            "side-image": true,
            "image-fader": this.state.enableImageFader
        });

        const responseMessageClass = classNames({
            "message-container": true,
            error: !this.state.hideForm
        });

        return (
            <div className="reset-password-container with-font-smoothing">
                <div className="brand-picture">
                    <div className={sideImageClass} />
                </div>
                <div className="reset-password-box">
                    <div className="brand-container-wrapper">
                        <div className="brand-container">
                            <a href="/">
                                <div className="logo" />
                                <div className="name">
                                    <h1>JSCRUNCH</h1>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="title">
                        <h1>Forgot password?</h1>
                        <div className="reset-description">
                            Don't worry, just enter the email address you registered with and
                            we will send you a password reset link.
                        </div>
                    </div>
                    <div className={responseMessageClass}>{this.state.responseMessage}</div>
                    <div className={`create_account_form ${hideClass}`}>
                        <form onSubmit={this.submitAndContinue}>
                            <Alert show={showErrMsg} className={errorClass} closeLabel="" variant="danger" onClose={this.handleDismiss} dismissible>
                                {this.state.message}
                            </Alert>

                            <FormGroup controlId="emailAddress">
                                <FormControl
                                    type="text"
                                    bsSize="large"
                                    value={this.state.email}
                                    placeholder="Your email address"
                                    onChange={this.handleEmailInput}
                                />
                            </FormGroup>

                            <button type="submit" className="button button_wide">
                                Reset password
                            </button>

                            <div className="separator" />
                            <Link className="other-link" to="/login">Back to login</Link>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStoreToProps = store => ({
    authorize: store.login.authorize
});

export default connect(mapStoreToProps, {})(ResetPassword);
