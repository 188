import { LOGIN_SUBMITTED, AUTH_CHANGE, LOGIN_RESET } from "../actions/login.action";

const INITIAL_STATE = {
    authorize: false,
    user: null,
    error: null
};

export function loginReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case LOGIN_SUBMITTED:
            return { ...state, ...action.values };
        case AUTH_CHANGE:
            return { ...state, ...action.values };
        case LOGIN_RESET:
            return { ...state, ...action.values };
        default:
            return state;
    }
}
