import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Alert, FormGroup, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isValidEmail } from "../utils/helper.util";

// action
import { loginAction, onLoginSubmitted, loginResetAction } from "../actions/login.action";

import "./login-signup-reset.scss";
import "./common/brand.scss";
import "./common/button.scss";

class Login extends Component {
    state = {
        email: "",
        password: "",
        message: [],
        hasError: false,
        enableImageFader: false
    }

    componentDidMount() {
        if (this.props.authorize) {
            this.props.history.push("/app");
        }

        setTimeout(() => {
            this.setState({
                enableImageFader: true
            });
        }, 100);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.authorize) {
            this.props.history.push("/app");
        }

        this.setState({
            hasError: !!nextProps.login.error,
            message: [nextProps.login.error]
        });
    }

    handlePasswordInput = (event) => {
        this.setState({
            password: event.target.value
        });
    }

    handleEmailInput = (event) => {
        const email = event.target.value || "";

        this.setState({
            email
        });
    }

    getEmailValidationState = (email) => {
        const length = email.length;

        if (!length) {
            this.setState({
                showEmailErrorMessage: true,
                emailErrorMessage: "Email address cannot be empty"
            });
        } else {
            // validate email
            const isValid = isValidEmail(email);

            if (isValid) {
                this.setState({
                    showEmailErrorMessage: false,
                    emailErrorMessage: ""
                });
            } else {
                this.setState({
                    showEmailErrorMessage: true,
                    emailErrorMessage: "Email is invalid"
                });
            }
        }
    }

    submitAndContinue = (event) => {
        event.preventDefault();

        const email = this.state.email;
        const emailIsValid = isValidEmail(email);
        const password = this.state.password;
        const passwordIsValid = !!password.length;
        const errorMessages = [];

        const canProceed = emailIsValid && passwordIsValid;

        if (canProceed) {
            const data = {
                email,
                password: this.state.password
            };

            this.setState({
                hasError: false,
                message: []
            });

            this.props.loginAction(data);
        } else {
            if (!emailIsValid) {
                if (!email.length) {
                    errorMessages.push("Email address cannot be empty");
                } else {
                    errorMessages.push("Email is invalid");
                }
            }

            if (!passwordIsValid) {
                errorMessages.push("Password cannot be empty");
            }

            this.setState({
                hasError: true,
                message: errorMessages
            });
        }
    }

    handleDismiss = () => {
        this.setState({
            hasError: false,
            message: []
        });
    }

    render() {
        const errorClass = classNames({
            "top-error-box": true,
        });
        const showErrMsg = this.state.hasError;

        const sideImageClass = classNames({
            "side-image": true,
            "image-fader": this.state.enableImageFader
        });

        const items = [];
        const message = this.state.message;
        let errorMessage = "";

        for (let i = 0, l = message.length; i < l; i++) {
            errorMessage = message[i];
            items.push(<div>{errorMessage}</div>);
        }

        return (
            <div className="login-container with-font-smoothing">
                <div className="brand-picture">
                    <div className={sideImageClass} />
                </div>
                <div className="login-box">
                    <div className="brand-container-wrapper">
                        <div className="brand-container">
                            <a href="/">
                                <div className="logo" />
                                <div className="name">
                                    <h1>JSCRUNCH</h1>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="title">
                        <h1>
                            <div>Welcome back.</div>
                        </h1>
                    </div>
                    <div className="create_account_form">
                        <form onSubmit={this.submitAndContinue}>
                            <Alert show={showErrMsg} className={errorClass} closeLabel="" variant="danger" onClose={this.handleDismiss} dismissible>
                                {items}
                            </Alert>

                            <FormGroup controlId="emailAddress">
                                <FormControl
                                    type="text"
                                    bsSize="large"
                                    value={this.state.email}
                                    placeholder="Your email address"
                                    onChange={this.handleEmailInput}
                                />
                            </FormGroup>

                            <FormGroup controlId="password">
                                <FormControl
                                    type="password"
                                    bsSize="large"
                                    value={this.state.password}
                                    placeholder="Your password"
                                    onChange={this.handlePasswordInput}
                                />
                            </FormGroup>

                            <button type="submit" className="button button_wide">
                                Log in
                            </button>
                            <div className="separator" />
                            <Link className="other-link" to="/reset-password">Forgot password?</Link>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStoreToProps = store => ({
    authorize: store.login.authorize,
    login: store.login
});

export default connect(mapStoreToProps, { loginAction, onLoginSubmitted, loginResetAction })(Login);
