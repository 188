import React, { Component } from "react";
import { connect } from "react-redux";
import { 
    Button,
    Glyphicon,
    Tooltip,
    Overlay
} from "react-bootstrap";
import SplitPane from "react-split-pane";
import CodeMirror from "react-codemirror";
import InnerCodeMirror from "codemirror";
import ReactQuill from "react-quill";
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import CookieConsent from "react-cookie-consent";
import { Book } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { getCookie } from "../utils/cookie.util";
import { API_URL } from "../config";
import { SecureIframe } from "./secureIframe.react";
import { Result } from "./common";
import Icon from "./signup/icon.react";
import { codeEditorQuery } from "../actions/codeEditor.action";
import { loadQuestion, onDoneLoadingQuestion } from "../actions/question.action";
import { LastCodeSubmissionModal, SolutionModal } from "./modals";
import { setActiveQuestion } from "../actions/sideBar.action";
import {
    questionSubmitUserCode,
    questionResetRunTest,
    onQuestionDoneRunningTest,
    onQuestionRunningTest,
    ON_QUESTION_RESET_RUN_TEST
} from "../actions/questionRunTest.action";
import { authQuery } from "../actions/login.action";
import { startPrompt, clearOutput } from "../utils/console.util";

import "codemirror/lib/codemirror.css";
import "codemirror/theme/elegant.css";
import "codemirror/addon/lint/lint.css";
import "./question.scss";
import "./console.scss";


// KEYCODES: left, up, right, bottom arrows, enter, esc, backspace, command(left), command(right), TAB, caplocks, option, control
const KEYCODES = [37, 38, 39, 40, 13, 27, 8, 91, 93, 9, 20, 16, 17, 18];
const consoleHeader = "Native Browser JavaScript";
const tabIds = {
    console: "1",
    test: "2"
};
const options = {
    mode: "javascript",
    readOnly: false,
    lineNumbers: true,
    lineWrapping: true,
    theme: "elegant",
    indentWithTabs: true,
    smartIndent: true,
    autoCloseTags: true,
    autoCloseBrackets: true,
    tabSize: 2,
    styleActiveLine: {
        nonEmpty: true
    },
    autofocus: true,
    styleSelectedText: true,
    showCursorWhenSelecting: true,
    lint: {
        esversion: 6
    },
    gutters: ["CodeMirror-lint-markers"],
    hintOptions: {
        completeSingle: false
    }
};
const UPDATE_USER_CODE_DELAY = 1200;

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

class Question extends Component {
    constructor(props) {
        super(props)
        this.solutionButton = React.createRef();
    }

    state = {
        consoleAndTestState: {
            currentTab: tabIds.console
        },
        code: "",
        verticalPane1Width: 600,
        showSolutionModal: false,
        showLastSubmissionModal: false,
        showQuestionDropdown: false,
        showLastSubmissionButton: false,
        isSavingCode: false,
        showCodeSaved: false,
        showSolutionButton: false,
        showSolutionButtonTooltip: false
    };

    componentDidMount() {
        const { questionObj } = this.props.questionObj;
        const authorize = !!getCookie("authorize");
        const isLoggedIn = !!this.props.login.user;

        if (authorize) {
            if (!isLoggedIn) {
                this.props.authQuery();
            } else {
                this.setState({
                    showLastSubmissionButton: true
                })
            }
        }

        if (!questionObj) {
            this.props.loadQuestion(this.props.match.params.id);
        } else {
            this.initiateTemplate(questionObj);
        }
        this.updateDimension();
        this.startConsole();

        window.addEventListener("resize", this.updateDimension);

        this.updateUserCodeDebounced = AwesomeDebouncePromise(
            this.updateUserCode,
            UPDATE_USER_CODE_DELAY
        );

        // show or disable solution button
        this.setState({
            showSolutionButton: isLoggedIn
        });
    }

    componentWillReceiveProps(props) {
        const { questionObj } = this.props.questionObj;
        const newQuestion = props.questionObj.questionObj;

        // set sidebar question active
        if (questionObj) {
            this.props.setActiveQuestion(questionObj.id);
        }

        if ((!questionObj && newQuestion) || (questionObj && newQuestion && questionObj.id !== newQuestion.id)) {
            this.initiateTemplate(newQuestion);
        }

        const authorize = !!getCookie("authorize");

        this.setState({
            showLastSubmissionButton: authorize
        })

    }

    updateDimension = () => {
        const splitPaneWrapperRect = this.refs.splitPaneWrapper.getBoundingClientRect() || {};
        this.setState({
            verticalPane1Width: (Math.ceil(splitPaneWrapperRect.width * 0.56) || 550)
        });
    }

    updateUserCode = (code, questionId) => {
        window
            .fetch(`${API_URL}/api/user/question/` + questionId + "/update", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${getCookie("authorize")}`
                },
                body: JSON.stringify({code: code})
            })
            .then(res => {
                this.setState({
                    isSavingCode: false,
                    showCodeSaved: true,
                });

                return res.json();
            })
    }

    updateQuestionDropdownSetting = value => {
        this.setState({ showQuestionDropdown: value });
    };

    startConsole = () => {
        this.jqconsole = $(".console").jqconsole(consoleHeader, ">> "); // eslint-disable-line
        startPrompt(this.jqconsole);
    };

    handleIframeReturnResult = data => {
        const { state: { consoleAndTestState } } = this;

        if (consoleAndTestState.currentTab === tabIds.test) {
            // obsolete if
        } else {
            const lastReturnResult = data.lastReturnResult;
            const runResult = data.result;
            const hasCalledConsoleLog = data.hasCalledConsoleLog;
            let str = "";

            if (runResult && runResult.error) {
                str = runResult.message;
            } else {
                str = runResult;
            }

            clearOutput(this.jqconsole);
            // During refresh of the console output,
            // add a delay to writing new output to create
            // a visual refresh effect
            setTimeout(() => {
                const jqconsole = this.jqconsole;
                jqconsole.Append(`<span class="jqconsole-header"><span class="">${consoleHeader}</span></span>`);
                jqconsole.AbortPrompt();

                // console.log gets called at least once before
                // so print the result
                if (hasCalledConsoleLog) {
                    jqconsole.Write(`${str}\n`, "jqconsole-output");
                }

                // print the output of the last executed instruction (usually a function call)
                jqconsole.Write(`=> ${lastReturnResult}\n`, "jqconsole-last-return-result");

                startPrompt(this.jqconsole);
            }, 20);
        }
    };

    onClick = async event => {
        const { props: { questionObj: { questionObj } }, state: { consoleAndTestState } } = this;
        const name = event && event.currentTarget && event.currentTarget.name;
        const code = this.codeMirror.getValue();
        let payload;

        if (name === "runTest" && questionObj) {
            if (consoleAndTestState.currentTab !== tabIds.test) {
                this.setState({
                    consoleAndTestState: {
                        currentTab: tabIds.test
                    }
                });
            }

            if (this.props.match.params.id) {
                this.props.questionSubmitUserCode(this.props.match.params.id, code);
            }
        } else if (name === "run") {
            this.startConsole();
            if (consoleAndTestState.currentTab !== tabIds.console) {
                this.setState({
                    consoleAndTestState: {
                        currentTab: tabIds.console
                    }
                });
            }

            payload = [code];

            this.secureIframe.pageSendToIframe("run", payload);
        } else if (name === "last-submission") {
            this.setState({
                showLastSubmissionModal: true
            });

            // this.props.questionLastSubmission(this.props.match.params.id);
        } else if (name === "solution") {
            const isLoggedIn = !!this.props.login.user;

            if (isLoggedIn) {
                this.setState({
                    showSolutionModal: true
                });
            }
        }
    };

    onTabClick = event => {
        const id = event.target.getAttribute("data-id");

        this.setState({
            consoleAndTestState: {
                currentTab: id // consoleAndTestState
            }
        });
    };

    editorRefCallback = ref => {
        if (ref) {
            const cm = ref.getCodeMirror();
            cm.setSize("100%", "100%");

            this.codeMirror = cm;

            this.codeMirror.on("keyup", function(cm, event) {
                if (!cm.state.completionActive &&   /*Enables keyboard navigation in autocomplete list*/
                    KEYCODES.indexOf(event.keyCode) === -1 ) {  /*do not open autocomplete list just after item has been selected in it*/

                    let cursor = cm.getCursor(), line = cm.getLine(cursor.line);
                    let start = cursor.ch, end = cursor.ch;
                    while (start && /\w/.test(line.charAt(start - 1))) --start;
                    while (end < line.length && /\w/.test(line.charAt(end))) ++end;
                    let word = line.slice(start, end).toLowerCase();

                    if (word.length) {
                        InnerCodeMirror.showHint(cm);
                    }
                }


            })
        }
    };

    initiateTemplate = question => {
        const functionName = question.templateName;
        const userSavedCode = question.userSavedCode;
        const helperFunctionCode = question.helper_function_code;
        const parameters = this.getFormattedParams(question.params);
        const parametersInfo = this.getFormattedParamsInfo(question.params);

        // reset run test component
        this.props.questionResetRunTest();

        // return type
        let type = question.returnType.type + "[]".repeat(question.returnType.arrayDimension);
        const returnType = ` * @return {${type}}\n`;

        // more param info
        let moreParamInfo = this.getFormattedMoreParamInfo(question.moreParamInfo);

        let code = "";
        if (userSavedCode) {
            code = userSavedCode;
        } else {
            code = 
                `/**\n` +
                `${moreParamInfo}` +
                `${parametersInfo}` +
                `${returnType}` +
                ` */\n` +
                `function ${functionName}(${parameters}) {\n` +
                `\t// your code here\n` +
                `\t\n` +
                `}`;

            if (helperFunctionCode) {
                code += '\n\n' + helperFunctionCode;
            }
        }

        // set value
        this.codeMirror.refresh();
        this.codeMirror.setValue(code);

        // focus cursor
        if (userSavedCode) {
            let lastLineHandle = this.codeMirror.getLineHandle(this.codeMirror.lineCount() - 1);
            let focusIndex = lastLineHandle.text && lastLineHandle.text.length;
            this.codeMirror.setCursor(this.codeMirror.lineCount() - 1, focusIndex);
        } else {
            this.codeMirror.setCursor(this.codeMirror.lineCount() - 2, 2);
        }

        // Will need this delay to focus on the code editor
        setTimeout(function(){
            this.codeMirror.focus();
        }.bind(this), 1);
    };

    getFormattedParams = params => {
        let result = [];
        let param = null;

        for (let i = 0, l = params.length; i < l; i++) {
            param = params[i];
            result.push(param.name);
        }

        return result.join(", ");
    };

    getFormattedParamsInfo = params => {
        const squareBracket = "[]";
        let result = "";
        let param = null;
        let type = "";

        for (let i = 0, l = params.length; i < l; i++) {
            param = params[i];
            type = param.type + squareBracket.repeat(param.arrayDimension);
            result += ` * @param {${type}} ${param.name}\n`;
        }

        return result;
    }

    getFormattedMoreParamInfo = (moreParamInfo) => {
        let result = "";
        let tokenize = [];

        if (!moreParamInfo.length) return result;

        tokenize = moreParamInfo.replace(/↵/ig, "\n").split(/\r?\n/);

        for (let i = 0, l = tokenize.length; i < l; i++) {
            if (i===0) {
                result += ` * ${this.upperCaseFirst(tokenize[i])}\n`;
            } else {
                result += ` * ${tokenize[i]}\n`;
            }
        }

        if (result.length) {
            result += " *\n";
        }

        return result;
    }

    updateCode = newCode => {
        // don't save code if not logged in
        if (!this.props.login.user) {
            return;
        }

        const questionId = this.props.match.params.id;
        this.setState({
            isSavingCode: true,
            showCodeSaved: false
        })

        this.updateUserCodeDebounced(newCode, questionId);
    }

    updateState = value => {
        this.setState(value);
    };

    upperCaseFirst = string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    setShowSolutionButton = showSolutionButtonTooltip => {
        console.log("****** setting showSolutionButton:", showSolutionButtonTooltip)
        const isLoggedIn = !!this.props.login.user;
        
        showSolutionButtonTooltip = isLoggedIn ? false : showSolutionButtonTooltip;

        this.setState({
            showSolutionButtonTooltip
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimension);
    }

    render() {
        const {
            state: {
                consoleAndTestState,
                code,
                verticalPane1Width,
                showSolutionModal,
                showLastSubmissionModal,
                showQuestionDropdown,
                showLastSubmissionButton,
                isSavingCode,
                showCodeSaved,
                showSolutionButton,
                showSolutionButtonTooltip
            },
            props: {
                questionObj: { questionObj },
                questionRunTest: { testResult, isQuestionRunningTest }
            },
            editorRefCallback,
            onClick,
            onTabClick,
            updateState,
            updateQuestionDropdownSetting,
        } = this;

        const consoleTestClassName =
            consoleAndTestState.currentTab === tabIds.console ? "is-console-tab" : "is-test-tab";
        const functionName = questionObj ? questionObj.templateName : "";
        const questionId = questionObj && questionObj.id;

        return (
            <div className={`${consoleTestClassName} question-container`}>
                <div className="question-header-container with-font-smoothing">
                    <div className="exercise-icon">
                    <i className="pencil-outline"> <Icon type="pencil-outline" /> </i>
                    </div>
                    <div className="title">
                        {functionName}
                    </div>
                    <div className="control-container">
                        <div className="submit-button-wrapper with-font-smoothing">
                            <Button className="float-right margin-left-10" name="runTest" onClick={onClick}>
                                <i className="cloud-check"> <Icon type="cloud_check" /> </i>
                                <span>Submit Solution</span>
                            </Button>
                            <Button className="run-code float-right margin-left-10" name="run" onClick={onClick}>
                                <i className="play"> <Icon type="play" /> </i>
                                <span>Run Code</span>
                            </Button>
                            <div className="save-status">
                                { isSavingCode ? <span>Saving...</span> : showCodeSaved ? <span>Saved to Cloud</span> : null }
                            </div>
                            { //showLastSubmissionButton ? 
                                //<Button className="last-submission float-right" name="last-submission" onClick={onClick}>
                                    //<i className="play"> <Icon type="file-code" /> </i>
                                    //<span>Last Submission</span>
                                //</Button> :
                                //null
                            }
                        </div>
                    </div>
                    <div className="console-control">
                        <Button
                            data-id={tabIds.console}
                            className="float-right margin-left-5"
                            name="showConsole"
                            onClick={onTabClick}
                        >
                            Console
                        </Button>
                        <div className="divider" />
                        <Button
                            data-id={tabIds.test}
                            className="float-right margin-right-5"
                            name="showTestResult"
                            onClick={onTabClick}
                        >
                            Test Result
                        </Button>
                    </div>
                </div>
                <div className="split-pane-wrapper" ref="splitPaneWrapper">
                    <LastCodeSubmissionModal updateState={updateState} showModal={showLastSubmissionModal} questionId={questionId} />
                    <SolutionModal updateState={updateState} showModal={showSolutionModal} questionId={questionId} />
                    <SplitPane split="vertical" minSize={10} maxSize={-20} defaultSize={verticalPane1Width}>
                        <section className="split-section">
                                <div className="editor-container">
                                    <CodeMirror
                                        ref={editorRefCallback}
                                        value={code}
                                        options={options}
                                        onChange={this.updateCode}
                                        autoSave
                                    />
                                </div>
                        </section>
                        <section className="split-section">
                            <SplitPane split="horizontal" minSize={20} maxSize={-20} defaultSize={150}>
                                <div className="console-and-test-container">
                                    <div className="console" />
                                    <div className="test-container">
                                        <Result result={testResult} runningTest={isQuestionRunningTest} />
                                    </div>
                                </div>
                                <div className="question-description with-font-smoothing">
                                    <p className="description-header">
                                        <span className="header-text">Instruction</span>
                                    </p>
                                    <Button ref={this.solutionButton} className={"solution-button float-right margin-left-10" + (showSolutionButton ? "" : " disable-solution")} name="solution" onClick={onClick} onMouseEnter={() => this.setShowSolutionButton(true)} onMouseLeave={() => this.setShowSolutionButton(false)}>
                                        <Book className="book-icon" size="17px" />
                                        <span>Solution</span>
                                    </Button>
                                    <Overlay target={this.solutionButton.current} show={showSolutionButtonTooltip} placement="bottom">
                                        {(props) => (
                                            <Tooltip id="solution-button-overlay" {...props}>
                                                Log in to access solution
                                            </Tooltip>
                                        )}
                                    </Overlay>
                                    <div className="description">
                                        <ReactQuill
                                            theme="snow"
                                            modules={{toolbar:false}}
                                            readOnly={true}
                                            value={questionObj ? questionObj.description : null}
                                        />
                                    </div>
                                </div>
                            </SplitPane>
                        </section>
                    </SplitPane>
                </div>
                <CookieConsent
                    location="bottom"
                    buttonText="OK"
                    cookieName="cookieConsent"
                    style={{ alignItems: "flex-start", padding:"0 20px", background: "#495057", color: "white", border: "1px solid darkgray", fontSize: "15px" }}
                    buttonStyle={{ fontSize: "14px", fontWeight: "700", height: "30px", width: "80px", color: "white", backgroundColor: "#17a2b8" }}
                    expires={150}
                >
                    <p className="cookie-paragraph">This website stores cookies on your computer. We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors on this website.</p>
                    <p className="cookie-paragraph">To find out more about the cookies we use, see our <Link style={{color:"#17a2b8"}} to="/privacy">Privacy Policy</Link>.</p>
                </CookieConsent>
                <div className="question-footer-container" />
                <SecureIframe
                    ref={secureIframe => {
                        this.secureIframe = secureIframe;
                    }}
                    handler={this.handleIframeReturnResult}
                />
            </div>
        );
    }
}

const mapStoreToProps = store => ({
    codeEditor: store.codeEditor,
    questionRunTest: store.questionRunTest,
    questionObj: store.questionObj,
    login: store.login
});

export default connect(mapStoreToProps, {
    codeEditorQuery,
    authQuery,
    questionSubmitUserCode,
    questionResetRunTest,
    onQuestionDoneRunningTest,
    onQuestionRunningTest,
    onDoneLoadingQuestion,
    ON_QUESTION_RESET_RUN_TEST,
    loadQuestion,
    setActiveQuestion
})(Question);
