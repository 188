import { ON_SIDE_BAR_CHANGE, SIDE_BAR_CHANGE, ACTIVE_QUESTION_CHANGE } from "../actions/sideBar.action";

const INITIAL_STATE = {
    questions: null,
    question: null,
    result: null,
    activeId: ''
};

export function sideBarReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SIDE_BAR_CHANGE: {
        return { ...state, ...action.values };
    }
    case ON_SIDE_BAR_CHANGE: {
        return { ...state, ...action.values };
    }
    case ACTIVE_QUESTION_CHANGE: {
        return { ...state, ...action.values };
    }
    default:
        return state;
    }
}
