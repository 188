import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";

import { authQuery } from "../actions/login.action";
import { getCookie } from "../utils/cookie.util";
import { Doughnut } from 'react-chartjs-2';
import { questionsQuery } from "../actions/questions.action";

import "./dashboard.scss";

let crunchTimeData = {
    labels: [
        'Completed',
        'Uncompleted',
    ],
    datasets: [{
        data: [0, 0],
        backgroundColor: [
            '#28a745',
            '#ec4f4f',
        ],
        hoverBackgroundColor: [
            '#28a745',
            '#ec4f4f',
        ]
    }],
    width: 400,
    height: 400
};

let fullCourseData = {
    labels: [
        'Completed',
        'Uncompleted',
    ],
    datasets: [{
        data: [0, 0],
        backgroundColor: [
            '#28a745',
            '#ec4f4f',
        ],
        hoverBackgroundColor: [
            '#28a745',
            '#ec4f4f',
        ]
    }]
};

class Dashboard extends Component {
    state = {
        showLoginAlert: false
    };

    componentDidMount() {
        const authorize = !!getCookie("authorize");

        if (authorize) {
            this.props.questionsQuery({simple: true});

            if (!this.props.login.user) {
                this.props.authQuery();
            }

            this.setState({
                showLoginAlert: false
            })

        } else {
            this.setState({
                showLoginAlert: true
            })
        }
    }

    componentWillUnmount() {
    }

    render() {
        const {
            state: {
                showLoginAlert
            },
            props: {
                questions
            },
        } = this;

        if (!isNaN(questions.crunchCompletedQuestions) && !isNaN(questions.fullCompletedQuestions) && !isNaN(questions.crunchNotCompletedQuestions) && !isNaN(questions.fullNotCompletedQuestions)) {
            let data = [questions.crunchCompletedQuestions, questions.crunchNotCompletedQuestions];
            crunchTimeData.datasets[0].data = data;

            data = [questions.fullCompletedQuestions, questions.fullNotCompletedQuestions];
            fullCourseData.datasets[0].data = data;
        }

        return (
            <div className='dashboard-container'>
                { showLoginAlert ? (<Alert className="alert-container" variant="warning">Log in to auto-save your work and track your progress.</Alert>) : null }
                <div class="row">
                    <div className="core-foundation-stat-container">
                        <h3>Crunch time questions</h3>
                	    <Doughnut data={crunchTimeData} />
                    </div>
                    <div className="full-course-stat-container">
                        <h3>Crunch time + Full course questions</h3>
                        <Doughnut data={fullCourseData} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStoreToProps = store => ({
    login: store.login,
    questions: store.questions
});

export default connect(mapStoreToProps, {
    authQuery,
    questionsQuery
})(Dashboard);
