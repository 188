import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert, FormGroup, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { debounce, isValidEmail } from "../utils/helper.util";

// action
import { signupAction, onSignupSubmitted, signupResetAction } from "../actions/signup.action";

import "./login-signup-reset.scss";
import "./common/brand.scss";
import "./common/button.scss";

const VALIDATION_DELAY = 700;

class Signup extends Component {
    state = {
        email: "",
        emailTakenErrorMessage: "",
        name: "",
        password: "",
        confirmPassword: "",
        enableImageFader: false,
        showFullNameErrorMessage: false,
        showEmailErrorMessage: false,
        showPasswordMessage: false,
        showPasswordWarningMessage: false,
        showPasswordLetterValid: false,
        showPasswordNumberValid: false,
        showPasswordCharLengthValid: false,
        showConfirmPasswordMessage: false,
        confirmPasswordMessage: "",
        hasSecurePassword: false,
        emailErrorMessage: ""
    };

    componentDidMount() {
        if (this.props.authorize) {
            this.props.history.push("/");
        }

        setTimeout(() => {
            this.setState({
                enableImageFader: true
            });
        }, 100);

        this.getNameValidationStateDebounce = debounce(this.getNameValidationState, VALIDATION_DELAY);
        this.getEmailValidationStateDebounce = debounce(this.getEmailValidationState, VALIDATION_DELAY);
        this.getConfirmPasswordValidationStateDebounce = debounce(this.getConfirmPasswordValidationState, VALIDATION_DELAY);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.authorize) {
            this.props.history.push("/");
        }

        this.setState({
            emailTakenErrorMessage: nextProps.signup.error
        });
    }

    handleDismiss = () => {
        this.setState({
            emailTakenErrorMessage: false
        });
    }

    getNameValidationState = () => {
        const length = this.state.name.length;

        this.setState({
            showFullNameErrorMessage: !length
        });
    }

    handleEmailInput = (event) => {
        const email = event.target.value || "";

        this.setState({
            email
        });

        this.getEmailValidationStateDebounce(email);
    }

    getEmailValidationState = (email) => {
        const length = email.length;

        if (!length) {
            this.setState({
                showEmailErrorMessage: true,
                emailErrorMessage: "Email address cannot be empty"
            });
        } else {
            // validate email
            const isValid = isValidEmail(email);

            if (isValid) {
                this.setState({
                    showEmailErrorMessage: false,
                    emailErrorMessage: ""
                });
            } else {
                this.setState({
                    showEmailErrorMessage: true,
                    emailErrorMessage: "Email is invalid"
                });
            }
        }
    }

    handleEmailInputOnBlur = (event) => {
        const email = event.target.value || "";

        this.getEmailValidationState(email);
    }

    handleFullNameInput = (event) => {
        this.setState({
            name: event.target.value || ""
        });
    }

    handlePasswordInput = (event) => {
        const password = event.target.value || "";

        this.setState({
            password
        });

        this.getPasswordValidationState(password);
    }

    handlePasswordInputOnBlur = (event) => {
        const password = event.target.value || "";

        if (!password.length) {
            this.setState({
                showPasswordMessage: false
            });
        } else {
            this.getPasswordValidationState(password);
        }
    }

    getPasswordValidationState = (password) => {
        let validationResult = {};

        validationResult = this.validatePassword(password);

        this.setState({
            showPasswordMessage: true,
            showPasswordLetterValid: validationResult.hasCapitalLetter,
            showPasswordNumberValid: validationResult.hasNumber,
            showPasswordCharLengthValid: validationResult.hasMinChars,
            hasSecurePassword: validationResult.isAllValid,
            showPasswordWarningMessage: validationResult.isNotEmpty &&
                !validationResult.isAllValid &&
                (validationResult.hasMinChars || validationResult.hasCapitalLetter || validationResult.hasNumber)
        });
    }

    handleConfirmPasswordInput = (event) => {
        const confirmPassword = event.target.value || "";

        this.setState({
            confirmPassword
        });

        this.getConfirmPasswordValidationStateDebounce(confirmPassword);
    }

    handleConfirmPasswordInputOnBlur = (event) => {
        const confirmPassword = event.target.value || "";

        this.getConfirmPasswordValidationState(confirmPassword);
    }

    getConfirmPasswordValidationState = (confirmPassword) => {
        const length = confirmPassword.length;
        const password = this.state.password;
        let showMessage = false;
        let message = "";

        if (!password.length) {
            showMessage = true;
            message = "Please create a password";
        } else if (!length) {
            showMessage = true;
            message = "Please confirm your password";
        } else if (this.isValidConfirmPassword(confirmPassword)) {
            showMessage = false;
            message = "";
        } else {
            showMessage = true;
            message = "Passwords don't match";
        }

        this.setState({
            showConfirmPasswordMessage: showMessage,
            confirmPasswordMessage: message
        });
    }

    isValidPassword = (password) => this.validatePassword(password).isAllValid;

    isValidConfirmPassword = (confirmPassword) => confirmPassword === this.state.password;

    validatePassword = (password) => {
        const length = password.length;
        const isNotEmpty = length > 0;
        const hasMinChars = length >= 8;
        const hasCapitalLetter = password.replace(/[^a-z]/ig, "").length > 0;
        const hasNumber = /\d/.test(password);
        const isAllValid = isNotEmpty && hasMinChars && hasCapitalLetter && hasNumber;

        return {
            isNotEmpty,
            hasMinChars,
            hasCapitalLetter,
            hasNumber,
            isAllValid
        };
    }

    submitAndContinue = (event) => {
        event.preventDefault();

        const canProceed = isValidEmail(this.state.email)
            && this.isValidPassword(this.state.password)
            && this.isValidConfirmPassword(this.state.confirmPassword);

        if (canProceed) {
            const data = {
                name: this.state.name,
                email: this.state.email,
                password: this.state.password
            };

            this.props.signupAction(data);
        } else {
            this.getNameValidationState();
            this.getEmailValidationState(this.state.email);
            this.getConfirmPasswordValidationState(this.state.confirmPassword);
        }
    }

    componentWillUnmount() {
        this.props.signupResetAction();
    }

    render() {
        const emailTakenClasses = classNames({
            "top-error-box": true
        });
        const showEmailTakenMsg = !!this.state.emailTakenErrorMessage;

        const sideImageClass = classNames({
            "side-image": true,
            "image-fader": this.state.enableImageFader
        });

        const fullNameMessageClass = classNames({
            "full-name-message": true
        });
        const showFullNameMsg = !!this.state.showFullNameErrorMessage;

        const emailAddressMessageClass = classNames({
            "email-address-message": true,
            show: !!this.state.showEmailErrorMessage
        });
        const showEmailAddrMsg = !!this.state.showEmailErrorMessage;

        const passwordMessageClass = classNames({
            "password-message": true,
        });
        const showPasswordMsg = !!this.state.showPasswordMessage;

        const passwordStateClass = classNames({
            "password-group": true,
            "secure-password": this.state.hasSecurePassword,
            "has-warning": this.state.showPasswordWarningMessage
        });

        const passwordLetterClass = classNames({
            "password-letter": true,
            "is-valid": !!this.state.showPasswordLetterValid
        });

        const passwordNumberClass = classNames({
            "password-number": true,
            "is-valid": !!this.state.showPasswordNumberValid
        });

        const passwordCharLengthClass = classNames({
            "password-char-length": true,
            "is-valid": !!this.state.showPasswordCharLengthValid
        });

        const confirmPasswordMessageClass = classNames({
            "confirm-password-message": true,
        });
        const showConfirmPasswordMsg = !!this.state.showConfirmPasswordMessage;

        return (
            <div className="signup-container with-font-smoothing">
                <div className="brand-picture">
                    <div className={sideImageClass} />
                </div>
                <div className="signup-box">
                    <div className="brand-container-wrapper">
                        <div className="brand-container">
                            <a href="/">
                                <div className="logo" />
                                <div className="name">
                                    <h1>JSCRUNCH</h1>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="title">
                        <h1>
                            <div>Welcome to JavaScript Interview</div>
                            <div>Coding Platform</div>
                        </h1>
                    </div>
                    <div className="create_account_form">
                        <form onSubmit={this.submitAndContinue}>
                            <Alert show={showEmailTakenMsg} className={emailTakenClasses} closeLabel="" variant="danger" onClose={this.handleDismiss} dismissible>
                                {this.state.emailTakenErrorMessage}
                            </Alert>

                            <FormGroup controlId="userFullName">
                                <FormControl
                                    type="text"
                                    size="lg"
                                    value={this.state.name}
                                    placeholder="Your full name"
                                    onChange={this.handleFullNameInput}
                                    onFocus={this.handleFullNameInput}
                                    onBlur={this.getNameValidationState}
                                />
                                <Alert show={showFullNameMsg} className={fullNameMessageClass} variant="danger">
                                    Full name cannot be empty
                                </Alert>
                            </FormGroup>

                            <FormGroup controlId="emailAddress">
                                <FormControl
                                    type="text"
                                    size="lg"
                                    value={this.state.email}
                                    placeholder="Your email address"
                                    onChange={this.handleEmailInput}
                                    onBlur={this.handleEmailInputOnBlur}
                                />
                                <Alert show={showEmailAddrMsg} className={emailAddressMessageClass} variant="danger">
                                    {this.state.emailErrorMessage}
                                </Alert>
                            </FormGroup>

                            <div className={passwordStateClass}>
                                <FormGroup controlId="password">
                                    <FormControl
                                        type="password"
                                        size="lg"
                                        value={this.state.password}
                                        placeholder="Create a password"
                                        onChange={this.handlePasswordInput}
                                        onFocus={this.handlePasswordInput}
                                        onBlur={this.handlePasswordInputOnBlur}
                                    />
                                    <Alert show={showPasswordMsg} className={passwordMessageClass} variant="danger">
                                        {this.state.hasSecurePassword ? (
                                            <span>Now, that's a secure password</span>
                                        ) : (
                                            <span>
                                                Password must contain a <strong className={passwordLetterClass}>letter</strong> and a <strong className={passwordNumberClass}>number</strong>
                                                , and be minimum of <strong className={passwordCharLengthClass}>8 characters</strong>
                                            </span>
                                        )}
                                    </Alert>
                                </FormGroup>
                            </div>

                            <FormGroup controlId="confirmPassword">
                                <FormControl
                                    type="password"
                                    size="lg"
                                    value={this.state.confirmPassword}
                                    placeholder="Confirm password"
                                    onChange={this.handleConfirmPasswordInput}
                                    onBlur={this.handleConfirmPasswordInputOnBlur}
                                />
                                <Alert show={showConfirmPasswordMsg} className={confirmPasswordMessageClass} variant="danger">
                                    {this.state.confirmPasswordMessage}
                                </Alert>
                            </FormGroup>

                            <button type="submit" className="button button_wide">
                                Sign up
                            </button>
                            <div className="separator" />
                            <Link className="other-link" to="/login">Already have an account?</Link>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStoreToProps = store => ({
    authorize: store.login.authorize,
    signup: store.signup
});

export default connect(mapStoreToProps, { signupAction, onSignupSubmitted, signupResetAction })(Signup);
