import axios from "axios";
import { API_URL } from "../config";
import { getCookie, setCookie } from "../utils/cookie.util";
import { onLoginSubmitted } from "./login.action";

export const SIGNUP_SUBMITTED = "SIGNUP_SUBMITTED";
export const SIGNUP_RESET = "SIGNUP_RESET";
export const onSignupSubmitted = values => ({ type: SIGNUP_SUBMITTED, values });
export const onSignupReset = values => ({ type: SIGNUP_RESET, values });

export const signupResetAction = () => onSignupReset({ user: null, error: null });

const BEARER_KEYWORD = "Bearer ";

export const signupAction = (data = {}) => (
    dispatch => {
        axios.post(`${API_URL}/api/signup`, {
            name: data.name,
            email: data.email,
            password: data.password
        }, { withCredentials: true })
        .then(res => {
            if (res.data.error) {
                dispatch(onSignupSubmitted(res.data));
            } else {
                let user = res.data.user;

                // set cookie
                setCookie("authorize", BEARER_KEYWORD + user.webToken, document.domain);
                dispatch(onLoginSubmitted({authorize: `${getCookie("authorize")}`, user: user}));
            }
        }).catch(error => {
            // TBD: handle error here
            dispatch(onSignupSubmitted({error}));
        });
    }
);
