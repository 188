import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import Dashboard from "./dashboard.react";
import Question from "./question.react";
import { SideBar } from "./common";

import "./main-app.scss";

class MainApp extends Component {

    render() {
        return (
            <div className="main-app">
                <Route component={SideBar} />
                <Switch>
                    <Route exact match path="/app" component={Dashboard} />
                    <Route exact match path="/app/question/:id" component={Question} />
                </Switch>
            </div>
        );
    }
}

const mapStoreToProps = state => ({});

export default connect(mapStoreToProps, {})(MainApp);
