import { API_URL } from "../config";
import { getCookie, deleteCookie, setCookie } from "../utils/cookie.util";

export const LOGIN_SUBMITTED = "LOGIN_SUBMITTED";
export const LOGIN_RESET = "LOGIN_RESET";
export const AUTH_CHANGE = "AUTH_CHANGE";

export const onLoginSubmitted = values => ({ type: LOGIN_SUBMITTED, values });
export const onLoginReset = values => ({ type: LOGIN_RESET, values });

const BEARER_KEYWORD = "Bearer ";

export const logOut = (error) => {
    deleteCookie("authorize");
    return onLoginReset({ authorize: false, user: null, error: error });
};

export const loginResetAction = () => onLoginReset({ authorize: false, user: null, error: null });

export const loginAction = (data = {}) => (
    dispatch => {
        window
            .fetch(`${API_URL}/api/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: 'include',
                body: JSON.stringify({
                    email: data.email,
                    password: data.password
                })
            })
            .then(res => res.json())
            .then(data => {
                if (data.error) {
                    dispatch(logOut(data.error));
                } else {
                    let user = data.user;

                    // set cookie
                    setCookie("authorize", BEARER_KEYWORD + user.webToken, document.domain);

                    dispatch(onLoginSubmitted({authorize: `${getCookie("authorize")}`, user}));
                }
            })
            .catch(err => {
                dispatch(logOut(err));
            });
    }
);

export const authQuery = () => (
    dispatch => {
        window
            .fetch(`${API_URL}/api/user`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `${getCookie("authorize")}`
                }
            })
            .then(res => res.json())
            .then(data => {
                if (data.error) {
                    dispatch(logOut(data.error));
                } else {
                    dispatch(onLoginSubmitted({authorize: `${getCookie("authorize")}`, user: data.user}));
                }
            }).catch(err => {
                if (err) {
                    dispatch(logOut());
                }
            });
    }
);
