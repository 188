import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import axios from "axios";

import { API_URL } from "../config";

import "./about.scss";

class About extends Component {
    state = {
        aboutHtmlStr: ""
    }

    componentDidMount() {
        axios.get(`${API_URL}/api/about`)
        .then(res => {
            this.setState({
                aboutHtmlStr: res.data
            })

        }).catch(err => {
            if (err) {}
        });
    }

    render() {
        return (
            <div className="about-container with-font-smoothing" dangerouslySetInnerHTML={{__html: this.state.aboutHtmlStr}}>
            </div>
        );
    }
}

const mapStoreToProps = store => ({});

export default connect(mapStoreToProps, {})(About);
