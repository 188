import { CODE_EDITOR_CHANGE } from "../actions/codeEditor.action";

const INITIAL_STATE = {
    questions: [],
    question: null,
    result: null
};

export function codeEditorReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case CODE_EDITOR_CHANGE:
        return { ...state, ...action.values };
    default:
        return state;
    }
}
