import React, { Component } from "react";
import { Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Button, ListGroup } from "react-bootstrap";
import { CreditCard2Front, Check2 } from 'react-bootstrap-icons';
import CheckoutForm from "./checkoutForm.react";

import { connect } from "react-redux";
import { getCookie } from "../utils/cookie.util";
import { API_URL, STRIPE_PUBLISHABLE_KEY } from "../config";
import { onLoginSubmitted, authQuery } from "../actions/login.action";

import "./product.scss";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with your real test publishable API key.
const promise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const CRUNCH_COURSE = "crunch";
const FULL_COURSE = "full";

class Product extends Component {
    state = {
        isCrunchCourse: false,
        showPaymentForm: false,
        productId: FULL_COURSE,
        clientSecret: "",
        hasCompletedPayment: false
    }

    paymentIntentId = "";

    componentDidMount() {
        const authorize = !!getCookie("authorize");

        if (authorize && !this.props.login.user) {
            this.props.authQuery();
        }

        // Set your publishable key: remember to change this to your live publishable key in production
        // See your keys here: https://dashboard.stripe.com/account/apikeys
        // var stripe = Stripe('pk_test_TYooMQauvdEDq54NiTphI7jx');
        // var elements = stripe.elements();
    }

    selectPlan = (event) => {

        let coursePlanId = event.currentTarget.getAttribute("data-id");

        this.setState({
            isCrunchCourse: coursePlanId === CRUNCH_COURSE,
            productId: coursePlanId
        });

        if (this.paymentIntentId && !this.state.hasCompletedPayment) {
            window
            .fetch(`${API_URL}/api/payment/payment-intents/` + this.paymentIntentId, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({items: [{ id: coursePlanId }]})
            })
            .then(res => {
                return res.json();
            })
            .then(data => {
                this.setState({
                    clientSecret: data.clientSecret
                });
            });
        }
    }

    payWithCredit = () => {
        this.setState({
            showPaymentForm: true
        });

        // Create PaymentIntent as soon as the payment elements / page loads
        window
        .fetch(`${API_URL}/api/payment/create-payment-intent`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({items: [{ id: this.state.productId }]})
        })
        .then(res => {
            return res.json();
        })
        .then(data => {
            this.paymentIntentId = data.id;

            this.setState({
                clientSecret: data.clientSecret
            });
        });
    }

    completedPayment = (data) => {
        this.setState({
            hasCompletedPayment: true
        })
    }

    render() {
        const crunchCourseClass = this.state.isCrunchCourse ? "active" : "";
        const fullCourseClass = this.state.isCrunchCourse ? "" : "active";
        const showPaymentFormClass = this.state.showPaymentForm ? "show" : "";
        const hidePayButton = this.state.showPaymentForm ? "hide" : "";

        return (
            <div className="product-container with-font-smoothing">
                <div className="action-title text-center">Choose your plan</div>
                <div className="product-main-content">
                    <div className="product-plan-container">
                        <div className={this.state.hasCompletedPayment ? "hide" : ""}>
                            <div className="product-plan">
                                <div className="pricing-plan-container noselect">
                                    <div className={"panel pricing-panel " + crunchCourseClass} data-id={CRUNCH_COURSE} onClick={this.selectPlan}>
                                        <div className="plan-title-container panel-heading">
                                            <h3 className="plan-title text-center">Crunch Time</h3>
                                            <p className="plan-description text-center">Core foundation interview prep</p>
                                        </div>
                                        <div className="panel-body text-center">
                                            <div className="main-feature-description">
                                                <p className="text-center">3 weeks access</p>
                                            </div>
                                            <div className="price-tag-container">
                                                <p className="price-tag-cross">$197</p>
                                                <p className="price-tag">$21</p>
                                            </div>
                                        </div>
                                        <div className="select-button-container panel-footer text-center">
                                            <input type="radio" name="course-plan" checked={this.state.isCrunchCourse} />
                                        </div>
                                    </div>
                                </div>
                                <div className="pricing-plan-container most-popular noselect">
                                    <div className={"panel pricing-panel " + fullCourseClass} data-id={FULL_COURSE} onClick = {this.selectPlan}>
                                        <div className="plan-title-container panel-heading">
                                                <h3 className="plan-title text-center">Full Course</h3>
                                                <p className="plan-description text-center">Core foundation + Comprehensive interview prep</p>
                                        </div>
                                        <div className="panel-body text-center">
                                            <div className="main-feature-description">
                                                <p className="text-center">3 months access</p>
                                                <p className="text-center">+ 9 months free</p>
                                            </div>
                                            <div className="price-tag-container">
                                                <p className="price-tag-cross">$297</p>
                                                <p className="price-tag">$27</p>
                                            </div>
                                        </div>
                                        <div className="select-button-container panel-footer text-center">
                                            <input type="radio" name="course-plan" checked={!this.state.isCrunchCourse} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"stripe-form-container " + showPaymentFormClass}>
                            <div className="payment-form-container">
                                <Elements stripe={promise}>
                                    <CheckoutForm productId={this.state.productId} clientSecret={this.state.clientSecret} completedPayment={this.completedPayment} />
                                </Elements>
                            </div>
                        </div>
                        <div className={"pay-button " + hidePayButton}>
                            <Button type="button" onClick={this.payWithCredit} block>
                                <CreditCard2Front size={20} />
                                Pay with Credit / Debit
                            </Button>
                        </div>
                    </div>
                    <div className="features-container">
                        <div className="include-title text-center">All plans include:</div>
                        <ListGroup horizontal="sm" variant="flush">
                            <ListGroup.Item>
                                <Check2 className="check-icon" size={24} color="#5cb95c" />
                                <div className="description">70+ hours of core coding questions</div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Check2 className="check-icon" size={24} color="#5cb95c" />
                                <div className="description">Learning to identify wide variety of algorithmic patterns quickly</div>
                            </ListGroup.Item>
                        </ListGroup>
                        <ListGroup horizontal="sm" variant="flush">
                            <ListGroup.Item>
                                <Check2 className="check-icon" size={24} color="#5cb95c" />
                                <div className="description">Famous FAANG companies coding interview questions</div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Check2 className="check-icon" size={24} color="#5cb95c" />
                                <div className="description">Code submission with test run</div>
                            </ListGroup.Item>
                        </ListGroup>
                        <ListGroup horizontal="sm" variant="flush">
                            <ListGroup.Item>
                                <Check2 className="check-icon" size={24} color="#5cb95c" />
                                <div className="description">All-in-one study platform that includes online JS coding editor, test run, code save, progress tracking, and more.</div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Check2 className="check-icon" size={24} color="#5cb95c" />
                                <div className="description">Coding solution and explanation</div>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStoreToProps = store => ({
    login: store.login
});

export default connect(mapStoreToProps, { onLoginSubmitted, authQuery })(Product);
