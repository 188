import { ON_DONE_LOADING_QUESTION, ON_LOADING_QUESTION } from "../actions/question.action";

const INITIAL_STATE = {
    questionObj: null,
    isLoadingQuestion: false
};

export function questionReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case ON_DONE_LOADING_QUESTION: {
        return { ...state, ...action.values };
    }
    case ON_LOADING_QUESTION: {
        return { ...state, ...action.values };
    }
    default:
        return state;
    }
}
