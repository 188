import axios from "axios";
import { API_URL } from "../config";
import { getCookie } from "../utils/cookie.util";
import { logOut } from "./login.action";

export const CODE_EDITOR_CHANGE = "CODE_UI_CHANGE";

export const onCodeEditorChange = values => ({ type: CODE_EDITOR_CHANGE, values });

export const codeEditorQuery = (query, variables = null, index) => (
    (dispatch, getState) => {
        axios.post(`${API_URL}/graphql`, {
            query,
            variables
        }, {
            headers: { Authorization: `${getCookie("authorize")}` }
        })
        .then(res => {
            if (res.data.errors) {
                dispatch(logOut());
            } else if (res.data.data) {
                const store = getState().codeEditor;
                const { data } = res.data;
                if (data.addQuestion) {
                    dispatch(onCodeEditorChange({ questions: [...store.questions, data.addQuestion] }));
                } else if (data.updateQuestion && typeof index === "number") {
                    const questions = [...store.questions];
                    questions[index] = variables;
                    dispatch(onCodeEditorChange({ questions }));
                } else if (!data.deleteQuestion) {
                    dispatch(onCodeEditorChange(data));
                }
            }
        }).catch(err => {
            if (err) {
                dispatch(logOut());
            }
        });
    }
);
