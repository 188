export function setCookie(cookieName, cookieValue, validDomain) {
    const domainString = validDomain ? (`; domain=${validDomain}`) : "";
    // cookie max age = 365*24*60*60; // 365 days
    document.cookie = `${cookieName}=${encodeURIComponent(cookieValue)}; max-age=315569260000; path=/${domainString}`; // eslint-disable-line
}

export function getCookie(cookieName) {
    const name = `${cookieName}=`;
    const arr = document.cookie.split(";"); // eslint-disable-line
    for (let i = 0; i < arr.length; i++) {
        let current = arr[i];
        while (current.charAt(0) === " ") {
            current = current.slice(1, current.length);
        }
        if (current.indexOf(name) === 0) {
            return decodeURIComponent(current.slice(name.length, current.length));
        }
    }
    return null;
}

export function deleteCookie(cookieName) {
    let domain = window.location.host === "jscrunch.com" ? 'domain=.jscrunch.com;' : '';
    let cookieStr = `${cookieName}= ; path=/; ` + domain  + 'expires = Thu, 01 Jan 1970 00:00:00 GMT'; // eslint-disable-line
    document.cookie = cookieStr;
}
