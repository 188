import React, { Component } from "react";

export class Loader extends Component {
    render() {
        return (
            <div>
            	<div className="spinner-container">
					<div class="spinner">
					  <div class="bounce1"></div>
					  <div class="bounce2"></div>
					  <div class="bounce3"></div>
					</div>
				</div>
            </div>
        );
    }
}
