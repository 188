import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";
import _ from "underscore";
import { authQuery } from "../actions/login.action";
import { getCookie } from "../utils/cookie.util";
import Input from "./signup/input.react";
import InputError from "./signup/inputError.react";

// action
import {
    changeProfileAction,
    changeProfileResetAction,
    changePasswordAction,
    changePasswordResetAction
} from "../actions/account.action";

import "./account.scss";
import "./input.scss";
import "./input-error.scss";

class Account extends Component {
    state = {
        email: null,
        name: null,
        currentPassword: '',
        password: '',
        confirmPassword: '',
        changedProfileMessage: '',
        isChangingProfile: false,
        hasChangeProfileError: false,
        changedPasswordMessage: '',
        isChangingPassword: false,
        hasChangePasswordError: false
    };

    componentDidMount () {
        const authorize = !!getCookie("authorize");

        // check if use is logged in
        if (authorize && !this.props.login.user) {
            this.props.authQuery();
        } else if (!authorize) {
            this.props.history.push("/");
        }

        if (this.props.login.user) {
            this.setState({
                name: this.props.login.user.name,
                email: this.props.login.user.email
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.authorize) {
            this.props.history.push("/");
        }

        this.setState({
            changedProfileMessage: nextProps.accountStore.changedProfileMessage,
            isChangingProfile: nextProps.accountStore.isChangingProfile,
            hasChangeProfileError: !nextProps.accountStore.changedProfileSuccess,
            changedPasswordMessage: nextProps.accountStore.changedPasswordMessage,
            isChangingPassword: nextProps.accountStore.isChangingPassword,
            hasChangePasswordError: !nextProps.accountStore.changedPasswordSuccess
        });

        if (nextProps.login.user) {
            this.setState({
                name: nextProps.login.user.name,
                email: nextProps.login.user.email
            });
        }
    }

    handleCurrentPasswordInput = (event) => {
        this.setState({
            currentPassword: event.target.value
        });
    }

    handlePasswordInput = (event) => {
        if(!_.isEmpty(this.state.confirmPassword)){
            this.refs.passwordConfirm.isValid();
        }
        this.refs.passwordConfirm.hideError();
        this.setState({
           password: event.target.value
        });
    }

    handleConfirmPasswordInput = (event) => {
        this.setState({
            confirmPassword: event.target.value
        });
    }

    submitAndChangeProfile = (event) => {
        event.preventDefault();

        var canProceed = this.validateEmail(this.state.email)
            && this.state.name;

        if (canProceed) {
            var data = {
                name: this.state.name,
                email: this.state.email,
                id: this.props.login.user.id
            };

            this.props.changeProfileAction(data);
        } else {
            this.refs.email.isValid();
            this.refs.name.isValid();
        }
    }

    submitAndChangePassword = (event) => {
        event.preventDefault();

        var canProceed = this.refs.currentPassword.isValid()
            && this.refs.password.isValid()
            && this.refs.passwordConfirm.isValid();

        if (canProceed) {
            var data = {
                email: (this.props.login && this.props.login.user && this.props.login.user.email) || '',
                currentPassword: this.state.currentPassword,
                newPassword: this.state.password
            };

            this.props.changePasswordAction(data);
        } else {
            this.refs.currentPassword.isValid();
            this.refs.password.isValid();
            this.refs.passwordConfirm.isValid();
        }
    }

    verifyNewPassword = (event) => {
        return (event !== this.state.currentPassword)
    }

    isConfirmedPassword = (event) => {
        return (event == this.state.password)
    }

    handleNameInput = (event) => {
        this.setState({
            name: event.target.value
        })
    }

    handleEmailInput = (event) => {
        this.setState({
            email: event.target.value
        });
    }

    validateEmail = (event) => {
        // regex from http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(event);
    }

    isEmpty = (value) => {
        return !_.isEmpty(value);
    }

    componentWillUnmount () {
        this.props.changeProfileResetAction();
        this.props.changePasswordResetAction();
    }

    render() {
        const {
            state: {
                name,
                email,
                changedProfileMessage,
                isChangingProfile,
                hasChangeProfileError,
                changedPasswordMessage,
                isChangingPassword,
                hasChangePasswordError
            }
        } = this;

        let passwordErrorClass = classNames({
          'error': hasChangePasswordError
        });

        let profileErrorClass = classNames({
          'error': hasChangeProfileError
        });

        return (
            <div className="account-container with-font-smoothing">
                <section className="left-bar">
                    <div className="side-nav">
                        <ul>
                            <li>
                                Edit Profile
                            </li>
                        </ul>
                    </div>
                    <div className="vdivider-container">
                        <div className="vdivider" />
                    </div>
                </section>
                <section className="account-form-wrapper">
                    <div className="profile-form-container">
                        <div className="profile-form">
                            <form className="change-profile">
                                <Input
                                    text="Full Name"
                                    ref="name"
                                    validate={this.isEmpty}
                                    value={name}
                                    onChange={this.handleNameInput} 
                                    emptyMessage="full name can't be empty"
                                />

                                <Input 
                                    text="Email Address"
                                    ref="email"
                                    type="text"
                                    defaultValue={this.state.email} 
                                    validate={this.validateEmail}
                                    value={email}
                                    onChange={this.handleEmailInput}
                                    errorMessage="Email is invalid"
                                    emptyMessage="Email can't be empty"
                                    errorVisible={this.state.showEmailError}
                                />

                                <div className={`message-container ${profileErrorClass}`}>{changedProfileMessage}</div>

                                <Button
                                    type="submit"
                                    className="button button_wide primary"
                                    disabled={isChangingProfile}
                                    onClick={!isChangingProfile ? this.submitAndChangeProfile : null}
                                >
                                    {isChangingProfile ? 'Saving...' : 'Save profile'}
                                </Button>
                                <div className="separator" />
                            </form>
                            <form className="change-password">
                                <Input
                                    text="Password"
                                    type="password"
                                    ref="currentPassword"
                                    validate={this.isEmpty}
                                    minCharacters="8"
                                    requireCapitals="1"
                                    requireNumbers="1"
                                    value={this.state.currentPasssword}
                                    emptyMessage="Current password can't be empty"
                                    onChange={this.handleCurrentPasswordInput}
                                />

                                <Input
                                    text="New password"
                                    type="password"
                                    ref="password"
                                    validator="true"
                                    minCharacters="8"
                                    requireCapitals="1"
                                    requireNumbers="1"
                                    value={this.state.passsword}
                                    emptyMessage="New password is invalid"
                                    onChange={this.handlePasswordInput}
                                />

                                <Input 
                                    text="Confirm new password"
                                    ref="passwordConfirm"
                                    type="password"
                                    validate={this.isConfirmedPassword}
                                    value={this.state.confirmPassword}
                                    onChange={this.handleConfirmPasswordInput}
                                    emptyMessage="Please confirm your password"
                                    errorMessage="Passwords don't match"
                                />

                                <div className={`message-container ${passwordErrorClass}`}>{changedPasswordMessage}</div>

                                <Button
                                    type="submit"
                                    className="button button_wide primary"
                                    disabled={isChangingPassword}
                                    onClick={!isChangingPassword ? this.submitAndChangePassword : null}
                                >
                                    {isChangingPassword ? 'Saving...' : 'Change password'}
                                </Button>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStoreToProps = store => ({
    authorize: store.login.authorize,
    login: store.login,
    accountStore: store.account
});

export default connect(mapStoreToProps, {
    changeProfileAction,
    changeProfileResetAction,
    changePasswordAction,
    changePasswordResetAction,
    authQuery
})(Account);
