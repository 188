import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { HeaderBar } from "./components/common";

// App
import LandingPage from "./components/landingPage.react";
import MainApp from "./components/mainApp.react";
import Signup from "./components/signup.react";
import Login from "./components/login.react";
import Account from "./components/account.react";
import ResetPassword from "./components/resetPassword.react";
import ResetPasswordConfirm from "./components/resetPasswordConfirm.react";
import About from "./components/about.react";
import Term from "./components/term.react";
import Privacy from "./components/privacy.react";
import Product from "./components/product.react";

import { getCookie } from "./utils/cookie.util";

function PrivateRoute ({component: Component, to: To, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => getCookie("authorize") ? <Component {...props} />
                : <Redirect to={{pathname: To, state: {from: props.location}}} />}
        />
    )
}

function RouteToAppIfLogin ({component: Component, to: To, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => getCookie("authorize") ?
                <Redirect to={{pathname: '/app', state: {from: props.location}}} /> :
                <Component {...props} />
            }
        />
    )
}

function Routes() {
    return (
        <Switch>
            <RouteToAppIfLogin exact match path="/" component={LandingPage} />

            <Route path="/">
                <div id="app-container">
                    <Route component={HeaderBar} />     
                    <div className="main-container">
                        <div className="main-content">
                            <Switch>
                                {/* app */}
                                <Route path="/login" component={Login} />
                                <RouteToAppIfLogin path="/signup" component={Signup} />
                                <Route path="/upgrade" component={Product} />

                                <Route exact match path="/reset-password" component={ResetPassword} />
                                <Route path="/reset-password/confirm/:token" component={ResetPasswordConfirm} />

                                <Route path="/about" component={About} />
                                <Route path="/term" component={Term} />
                                <Route path="/privacy" component={Privacy} />

                                {/* this needs to be at last */}
                                { /*<PrivateRoute path="/app" to="/login" component={MainApp} />*/}
                                <Route path="/app" component={MainApp} />
                                <PrivateRoute path="/account" to="/login" component={Account} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </Route>
        </Switch>
    );
};

export default Routes;