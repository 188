import { ON_QUESTIONS_CHANGE, ON_QUESTION_SAVE } from "../actions/questions.action";

const INITIAL_STATE = {
    questions: null,
    questionsObj: null,
    questionsCategory: null,
    crunchCompletedQuestions: 0,
    fullCompletedQuestions: 0,
    crunchNotCompletedQuestions: 0,
    fullNotCompletedQuestions: 0
};

export function questionsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case ON_QUESTIONS_CHANGE: {
        return { ...state, ...action.values };
    }
    default:
        return state;
    }
}
