import axios from "axios";
import { API_URL } from "../config";
import { getCookie } from "../utils/cookie.util";

export const ON_QUESTION_DONE_RUNNING_TEST = "ON_QUESTION_DONE_RUNNING_TEST";
export const ON_QUESTION_RUNNING_TEST = "ON_QUESTION_RUNNING_TEST";
export const ON_QUESTION_RESET_RUN_TEST = "ON_QUESTION_RESET_RUN_TEST";

export const onQuestionDoneRunningTest = values => ({ type: ON_QUESTION_DONE_RUNNING_TEST, values });
export const onQuestionRunningTest = values => ({ type: ON_QUESTION_RUNNING_TEST, values });
export const onQuestionResetRunTest = values => ({ type: ON_QUESTION_RESET_RUN_TEST, values })

export const questionSubmitUserCode = (id, code) => (
    (dispatch) => {
        let body = {
            code
        };

        dispatch(onQuestionRunningTest({
            testResult: null,
            isQuestionRunningTest: true
        }));

        axios.post(`${API_URL}/api/submit-solution/question/${id}`, body, {
            headers: { Authorization: `${getCookie("authorize")}` }
        })
        .then(res => {
            if (res.data) {
                dispatch(onQuestionDoneRunningTest({
                    testResult: res.data,
                    isQuestionRunningTest: false 
                }));
            }

            // add error handling code

        }).catch(err => {
            if (err) {}
        });
    }
);

export const questionResetRunTest = () => (
    (dispatch) => {
        dispatch(onQuestionResetRunTest({
            testResult: null,
            isQuestionRunningTest: false
        }));
    }
);
