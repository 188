import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";

export class Result extends Component {
    render() {
        const {
            result,
            runningTest
        } = this.props;

        if (runningTest) {
            return (
                <img className="loading-gif animated fadeIn" src="/loading_icon.gif" alt="Loading" />
            );
        } else if (!result) {
            return (
                <span>{"// Click 'Submit Solution' to run code with tests"}</span>
            )
        } else if (result && result.success) {
            return (
                <ListGroup.Item
                    header="Test Passed"
                    variant="success"
                >
                    Tests Passed
                </ListGroup.Item>
            );
        }

        let isMessageObj = typeof result.message === "object";

        if (isMessageObj && result.message.isUnitTest) {
            if (result.message.isRunTimeErr) {
                return (
                    <ListGroup.Item
                        header="Runtime Error"
                        variant="danger"
                    >{result.message.failMessage}</ListGroup.Item>
                )
            } else {
                return (
                    <ListGroup.Item
                        header="Test Failed"
                        variant="danger"
                    >
                        <span className="result-detail output">
                            <span className="field">Output:</span>
                            <span className="value">{result.message.userOutput === undefined ? "undefined" : result.message.userOutput}</span>
                        </span>
                        <span className="result-detail expected">
                            <span className="field">Expected:</span>
                            <span className="value">{result.message.expectedOutput === undefined ? "undefined" : result.message.expectedOutput}</span>
                        </span>
                        <span className="result-detail fail-message">
                            <span className="field">Error message:</span>
                            <span className="value">{result.message.failMessage}</span>
                        </span>
                    </ListGroup.Item>
                )
            }
        } else {
            switch (typeof result.message) {
                case "object":
                    return (
                        <ListGroup.Item
                            header="Test Failed"
                            variant="danger"
                        >
                            <span className="result-detail input">
                                <span className="field">Input:</span>
                                <span className="value">{result.message.input}</span>
                            </span>
                            <span className="result-detail output">
                                <span className="field">Output:</span>
                                <span className="value">{result.message.userOutput}</span>
                            </span>
                            <span className="result-detail expected">
                                <span className="field">Expected:</span>
                                <span className="value">{result.message.expectedOutput}</span>
                            </span>
                        </ListGroup.Item>
                    );
                case "string":
                    return (
                        <ListGroup.Item
                            header="Runtime Error"
                            variant="danger"
                        >{result.message}</ListGroup.Item>
                    );
                default:
                    return null;
            }
        }
    }
}
