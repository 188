import React, { Component } from "react";
import {
  CardElement,
  ElementsConsumer
} from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import Icon from "./signup/icon.react";

import { isValidEmail } from "../utils/helper.util";

import "./checkoutForm.scss";

const CRUNCH_COURSE = "crunch";
const FULL_COURSE = "full";

const courseInfo = {
    [CRUNCH_COURSE]: {
        name: "Crunch Time",
        price: "$21"
    },
    [FULL_COURSE]: {
        name: "Full Course",
        price: "$27"
    }
}

const cardStyle = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#32325d"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

class CheckoutForm extends Component {
    state = {
        succeeded: false,
        error: null,
        emailError: null,
        processing: "",
        disabled: true,
        email: ""
    }

    handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        this.setState({
            disabled: event.empty,
            error: event.error ? event.error.message : ""
        })
    };

    handleSubmit = async ev => {
        ev.preventDefault();

        // validate email
        if (!isValidEmail(this.state.email)) {
            this.setState({
                emailError: this.state.email.length ? "Invalid email address." : "Please enter an email address"
            });
            return;
        } else {
            this.setState({
                emailError: ""
            })
        }

        this.setState({
            processing: true
        })

        const { stripe, elements } = this.props;
        const payload = await stripe.confirmCardPayment(this.props.clientSecret, {
            receipt_email: this.state.email,
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: ev.target.name.value
                }
            }
        });

        if (payload.error) {
            // error
            this.setState({
                error: `Payment failed ${payload.error.message}`,
                processing: false
            })
        } else {
            this.setState({
                error: null,
                processing: false,
                succeeded: true
            })

            this.props.completedPayment(payload);
        }
    };

    render() {
        const productName = courseInfo[this.props.productId].name;
        const price = courseInfo[this.props.productId].price;

        return (
            <div>
                <form id="payment-form" className={this.state.succeeded ? "hidden" : ""} onSubmit={this.handleSubmit}>
                    <div className="product-description">
                        <span>{productName}</span>
                        <span className="price">{price}</span>
                    </div>
                    <input
                        type="text"
                        value={this.state.email}
                        onChange={(e) => this.setState({email: e.target.value})}
                        placeholder="Enter email address"
                    />
                    {/* Show any error that happens when user click submit */}
                    {this.state.emailError && (
                        <div className="card-error" role="alert">
                            {this.state.emailError}
                        </div>
                    )}

                    <CardElement id="card-element" options={cardStyle} onChange={this.handleChange} />

                    {/* Show any error that happens when processing the payment */}
                    {this.state.error && (
                        <div className="card-error" role="alert">
                            {this.state.error}
                        </div>
                    )}
                    <button
                        disabled={this.state.processing || this.state.disabled || this.state.succeeded}
                        id="submit"
                    >
                        <span id="button-text">
                            {this.state.processing ? (
                                <div className="spinner" id="spinner"></div>
                            ) : (
                                "Pay"
                            )}
                        </span>
                    </button>
                    <div className="stripe-icon-container">
                        <Icon type="powered-by-stripe" />
                    </div>

                    {/* Show a success message upon completion */}
                </form>
                <p className={this.state.succeeded ? "result-message" : "result-message hidden"}>
                    Congratulations! Order complete and payment received. Please
                    <Link to="/login"> log in </Link> or
                    <Link to="/signup"> sign up </Link>
                    with your registered email address, <i>{this.state.email}</i> to get started.
                </p>
            </div>
        );
    }
}

const InjectedCheckoutForm = (props) => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <CheckoutForm stripe={stripe} elements={elements} {...props} />
    )}
  </ElementsConsumer>
);

export default InjectedCheckoutForm;
