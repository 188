import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import CodeMirror from "react-codemirror";
import axios from "axios";
import { API_URL } from "../../config";
import { getCookie } from "../../utils/cookie.util";

import "./lastCodeSubmissionModal.scss";

const defaultState = {
    submittedCode: "",
    updatedAt: "",
    hasRequestedLastSubmitted: false
};

const codeMirrorOptions = {
    mode: "javascript",
    readOnly: true,
    lineNumbers: true,
    lineWrapping: true,
    theme: "elegant",
    indentWithTabs: true,
    smartIndent: true,
    autoCloseTags: true,
    autoCloseBrackets: true,
    tabSize: 2,
    styleActiveLine: {
        nonEmpty: true
    },
    autofocus: false,
    styleSelectedText: true,
    showCursorWhenSelecting: false,
    lint: {
        esversion: 6
    },
    gutters: ["CodeMirror-lint-markers"],
    hintOptions: {
        completeSingle: false
    }
};

export class LastCodeSubmissionModal extends Component {
    state = { 
        submittedCode: "",
        updatedAt: "",
        hasRequestedLastSubmitted: false
    };

    handleClose = () => {
        const { updateState } = this.props;
        this.setState(defaultState);
        updateState({ showLastSubmissionModal: false });
    };

    editorRefCallback = ref => {
        if (ref) {
            const cm = ref.getCodeMirror();
            cm.setSize("100%", "100%");

            this.codeMirror = cm;
        }
    };

    loadCodeMirror = (lastCodeSubmission) => {
        // set value
        this.codeMirror.refresh();
        this.codeMirror.setValue(lastCodeSubmission.submitted_code);
    }

    componentDidUpdate() {
        if (this.props.showModal && !this.state.hasRequestedLastSubmitted) {
            this.setState({
                hasRequestedLastSubmitted: true
            });

            let id = this.props.questionId;

            axios.get(`${API_URL}/api/question/last-submitted`, {
                params: {
                    question_id: id
                },
                headers: { Authorization: `${getCookie("authorize")}` }
            })
            .then(res => {

                let question = {};

                if (res.data && res.data.data && res.data.data.length) {
                    const { data } = res.data;
                    const lastCodeSubmission = data[0];

                    // process updated at time
                    let dateAndTime = parseInt(lastCodeSubmission.updatedAt);

                    if (!!dateAndTime) {
                        dateAndTime = new Date(dateAndTime).toString();
                        dateAndTime = dateAndTime.split(" ");
                        let month = dateAndTime[1];
                        let day = dateAndTime[2];
                        let year = dateAndTime[3];
                        let time = dateAndTime[4];
                        time = time.split(":");
                        let hour = time[0];
                        let minute = time[1];
                        let displayDateAndTime = month + ' ' + day + ', ' + year +
                            ' at ' + hour + ':' + minute;

                        this.setState({
                            updatedAt: displayDateAndTime,
                            submittedCode: lastCodeSubmission.submitted_code
                        })
                    }

                    this.loadCodeMirror(lastCodeSubmission);
                }

                // add error handling code

            }).catch(err => {
                if (err) {}
            });
        }
    }

    render() {
        const {
            props: {
                showModal,
            },
            state: {
                submittedCode,
                updatedAt,
            },
            handleClose,
            editorRefCallback
        } = this;

        const hasSubmittedCode = !!submittedCode;

        return (
            <Modal className="last-code-submission-modal" show={showModal} onHide={handleClose}>
                {
                    hasSubmittedCode ?
                        <div><Modal.Header closeButton>
                            <Modal.Title>Last Submission</Modal.Title>
                        </Modal.Header><Modal.Body>
                            <CodeMirror
                                ref={editorRefCallback}
                                value={submittedCode}
                                options={codeMirrorOptions}></CodeMirror>
                        </Modal.Body><Modal.Footer>
                            submitted {updatedAt}
                        </Modal.Footer></div> :
                        <Modal.Header closeButton>
                            <Modal.Title>No Submission</Modal.Title>
                        </Modal.Header>
                }
            </Modal>
        );
    }
}
