import axios from "axios";
import { API_URL } from "../config";
import { getCookie } from "../utils/cookie.util";
import { logOut } from "./login.action";

export const ON_SIDE_BAR_CHANGE = "ON_SIDE_BAR_CHANGE";
export const SIDE_BAR_CHANGE = "SIDE_BAR_CHANGE";
export const ACTIVE_QUESTION_CHANGE = "ACTIVE_QUESTION_CHANGE";

export const onSideBarChange = values => ({ type: ON_SIDE_BAR_CHANGE, values });
export const sideBarChange = values => ({ type: SIDE_BAR_CHANGE, values });
export const onActiveQuestionChange = values => ({ type: ACTIVE_QUESTION_CHANGE, values });

export const sideBarQuery = (query, variables = null) => (
    (dispatch) => {
        axios.post(`${API_URL}/graphql`, {
            query,
            variables
        }, {
            headers: { Authorization: `${getCookie("authorize")}` }
        })
        .then(res => {
            if (res.data.errors) {
                dispatch(logOut());
            } else if (res.data.data) {
                const { data } = res.data;
                dispatch(onSideBarChange({ questions: data }));
            }
        }).catch(err => {
            if (err) {
                dispatch(logOut());
            }
        });
    }
);

export const changeQuestions = (query, variables = null, options = {}) => {
    const { currentTab, index } = options;
    return (dispatch, getState) => {
        axios.post(`${API_URL}/graphql`, {
            query,
            variables
        }, {
            headers: { Authorization: `${getCookie("authorize")}` }
        })
        .then(res => {
            if (res.data.errors) {
                dispatch(logOut());
            } else if (res.data.data) {
                const store = getState().sideBar;
                const { data } = res.data;
                if (data.addQuestion) {
                    const addQuestion = [...store.questions[currentTab], data.addQuestion];
                    dispatch(onSideBarChange({ questions: { ...store.questions, [currentTab]: addQuestion } }));
                } else if (data.updateQuestion && typeof index === "number") {
                    if (currentTab === variables.categoryId) {
                        const updateQuestion = store.questions[currentTab];
                        updateQuestion[index] = variables;
                        dispatch(onSideBarChange({ questions: { ...store.questions, [currentTab]: updateQuestion } }));
                    } else {
                        const updateQuestion = store.questions[variables.categoryId];
                        updateQuestion.push(variables);
                        store.questions[currentTab].splice(index, 1);
                        dispatch(onSideBarChange({ questions: store.questions }));
                    }
                } else if (!data.deleteQuestion) {
                    dispatch(sideBarChange(data));
                }
            }
        }).catch(err => {
            if (err) {
                dispatch(logOut());
            }
        });
    };
};

export const setActiveQuestion = (activeId) => {
    return (dispatch, getState) => {
        dispatch(onActiveQuestionChange({activeId}));
    }
};
