import React, { Component } from "react";

// NOTE:
// message type key and value has to be the same
const MessageType = {
    RUN_TEST_RESULT: "RUN_TEST_RESULT",
    RUN_RESULT: "RUN_RESULT"
};

const IFRAME_ID = `SecureIFrameID-${Math.floor(Math.random() * 1000)}`;
const CONFIG_STR = encodeURIComponent(
    JSON.stringify({
        iframeID: IFRAME_ID,
        parentURL: `${window.location.protocol}//${window.location.host}`
    })
);
const IFRAME_HOST = "https://jscrunch.org";

const IFRAME_STYLE = {
    width: "0",
    height: "0",
    display: "none"
};

export class SecureIframe extends Component {
    constructor(props) {
        super(props);
        this.iframeRef = React.createRef();
    }

    componentDidMount() {
        const self = this;
        const iframe = this.iframeRef.current;

        // generate unique id for the iframe
        self.iframeLoaded = false;
        self.iframe = iframe;

        // add event listener
        window.addEventListener("message", self.pageReceiveFromIframe.bind(self));

        iframe.onload = function () {
            self.iframeLoaded = true;
        };
    }

    pageSendToIframe = function (...args) {
        const self = this;
        let str = "";

        if (!self.iframe || !self.iframeLoaded) {
            return;
        }

        str = JSON.stringify(args);

        self.iframe.contentWindow.postMessage(str, IFRAME_HOST);
    };

    pageReceiveFromIframe(event) {
        let data = null;
        let type = null;
        let iframeID = null;

        // origin host validation:
        // if unmatched with iframe host that is set
        // do not process the message
        if (event.origin !== IFRAME_HOST) {
            return;
        }

        try {
            data = JSON.parse(event.data);
        } catch (ex) {
            return;
        }

        if (data && data.constructor === Array && data.length >= 2) {
            // last item in array is iframe ID
            iframeID = data.pop();

            // iframeID validation:
            // for invalid iframeID, do not process the message
            if (!(iframeID.constructor === String) || IFRAME_ID !== iframeID) {
                return;
            }

            // second last item in array is the message type
            type = data.pop();

            // message type validation:
            // if the type is not supported / defined,
            // do not process the message
            if (!type || !MessageType[type]) {
                return;
            }

            // Return data to handler
            // Note for handler:
            // [0] arguments/payload for that event
            // [1] message type
            if (type === MessageType.RUN_TEST_RESULT || type === MessageType.RUN_RESULT) {
                this.props.handler(data[0], type);
            }
        }
    }

    render() {
        return (
            <iframe
                className="secure-iframe"
                src={IFRAME_HOST}
                name={CONFIG_STR}
                id={IFRAME_ID}
                frameBorder="0"
                allowFullScreen=""
                style={IFRAME_STYLE}
                ref={this.iframeRef}
            />
        );
    }
}
