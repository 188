import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Nav, NavItem, Navbar } from "react-bootstrap";
import { logOut } from "../../actions/login.action";
import { Profile } from "./profile.react";
import { deleteCookie } from "../../utils/cookie.util";

import "./header.scss";

export class Header extends Component {
    state = {
        showSetting: false
    };

    handleSelect = selectedKey => {
        if (selectedKey === "logout") {
            this.props.history.push('/login');
            this.props.logOut();
        } else if (selectedKey === "account") {
            this.props.history.push(`/${selectedKey}`);
        } else {
            this.props.history.push(`/${selectedKey}`);
            return;
        }
        this.updateSetting(!this.state.showSetting);
    };

    updateSetting = value => {
        this.setState({ showSetting: value });
    };

    clickBrand = () => this.props.history.push('/');

    render() {
        const {
            props: {
                authorize,
                user,
                location: {
                    pathname
                }
            },
            state: {
                showSetting
            },
            handleSelect,
            updateSetting
        } = this;

        // Do not render headerbar for the following
        if (
            pathname.includes("/login") ||
            pathname.includes("/signup") ||
            pathname.includes("/reset-password")
        ) {
            return null;
        }

        return (
            <div className="header-container">
                <div className="brand-container" onClick={this.clickBrand}>
                    <div className="logo" />
                    <div className="name">
                        <h1>JSCRUNCH</h1>
                    </div>
                </div>
                {this.props.authorize ? (
                    <div className="right-panel with-font-smoothing">
                        <Profile updateSetting={updateSetting} showSetting={showSetting} handleSelect={handleSelect} />
                        <div className="premium-container">
                            <Link to="/upgrade">Premium</Link>
                        </div>
                    </div>
                ) : (
                    <div className="right-panel with-font-smoothing">
                        <div className="log-in-container">
                            <Link to="/login">Log in</Link>
                        </div>
                        <div className="sign-up-container">
                            <Link to="/signup">Sign up</Link>
                        </div>
                        <div className="premium-container">
                            <Link to="/upgrade">Premium</Link>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authorize: state.login.authorize,
        user: state.login.user || {}
    };
}

export const HeaderBar = connect(mapStateToProps, { logOut })(Header);
