import { ON_QUESTION_DONE_RUNNING_TEST, ON_QUESTION_RUNNING_TEST, ON_QUESTION_RESET_RUN_TEST } from "../actions/questionRunTest.action";

const INITIAL_STATE = {
    testResult: null,
    isQuestionRunningTest: false
};

export function questionRunTestReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case ON_QUESTION_DONE_RUNNING_TEST: {
        return { ...state, ...action.values };
    }
    case ON_QUESTION_RUNNING_TEST: {
        return { ...state, ...action.values };
    }
    case ON_QUESTION_RESET_RUN_TEST : {
        return { ...state, ...action.values };
    }
    default:
        return state;
    }
}
