import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getCookie } from "../utils/cookie.util";
import { onLoginSubmitted, authQuery } from "../actions/login.action";
import { Button } from "react-bootstrap";
import { HeartFill } from 'react-bootstrap-icons';

import { PrivacyAndTerm } from "./common/privacyAndTerm.react";

import "./landingPage.scss";

class LandingPage extends Component {
    componentDidMount() {
        const authorize = !!getCookie("authorize");

        if (authorize && !this.props.login.user) {
            this.props.authQuery();
        }
    }

    render() {
        return (
            <div id="landing-page-container">
                <div className="main-content">
                    <div className="headerContainer container-fluid">
                        <div className="brand-container row">
                            <div className="col col-md-8 col-12 offset-md-2 text-center">
                                <a className="logo-container" href="/">
                                    <div className="logo" />
                                    <div className="name">
                                        <h1>JSCRUNCH</h1>
                                    </div>
                                </a>
                                <div className="right-panel with-font-smoothing">
                                    <div className="log-in-container">
                                        <Link to="/login">Log in</Link>
                                    </div>
                                    <div className="sign-up-container">
                                        <Link to="/signup">Sign up</Link>
                                    </div>
                                    <div className="premium-container">
                                        <Link to="/upgrade">Premium</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="home container-fluid">
                        <div className="row">
                            <div className="col col-md-8 offset-md-2 text-center">
                                <div className="home-content">
                                    <div className="home-heading-container">
                                        <h1 className="brand-heading animated fadeIn">All JavaScript interview prep platform</h1>
                                        <div className="brand-subtitle animated fadeIn">
                                            <div>Learn and practice data structure and algorithm interview questions. Optimized for software engineer programming in JavaScript.</div>
                                        </div>
                                    </div>
                                    <div className="get-it-free-container">
                                        <Link to="/app">Try it free</Link>
                                    </div>
                                    <div className="app-image-container">
                                        <img src="/app-img.png"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="curve-border-container">
                        <svg xmlns="http://www.w3.org/2000/svg" class="curve-container__curve curve-three" viewBox="0 0 1440 68" enable-background="new 0 0 1440 68"><path d="m1622.3 1937.7c0 0-410.7 169.1-913.4 75.5-502.7-93.6-977.7 56.3-977.7 56.3v440h1891.1v-571.8" fill="#F4F5F7" transform="translate(0-1977)"></path></svg>
                    </div>
                    <div className="feature-container container-fluid">
                        <div className="row">
                            <div className="col col-md-8 offset-md-2 text-center">
                                <div class="row">
                                    <div className="col algorithm">
                                        <div className="feature-img-container">
                                            <img src="./algorithm.png"/>
                                        </div>
                                        <h4>
                                            Algorithms and data structures
                                        </h4>
                                        <p className="description">
                                            Learn and solve practical coding interview questions that are 
                                            asked by FAANG companies (Facebook, Amazon, Apple, Netflix and Alphabet (Google)).
                                            All questions are provided with coding solution and explanation.
                                        </p>
                                    </div>
                                    <div className="col people-coding">
                                        <div className="feature-img-container">
                                            <img src="./people-coding.png"/>
                                        </div>
                                        <h4>
                                            All-in-one study platform
                                        </h4>
                                        <p className="description">
                                            Code in our JS coding editor, run your program with tests, save your work and submission, track your progress, all in one place.
                                        </p>
                                    </div>
                                    <div className="col core-foundation">
                                        <div className="feature-img-container">
                                            <img src="./brick-foundation.png"/>
                                        </div>
                                        <h4>
                                            Core foundation question set
                                        </h4>
                                        <p className="description">
                                            70+ hours of core coding questions carefully curated with the help of community feedback. 
                                            These questions are designed to help identify wide variety of algorithmic patterns quickly.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mission-container container-fluid">
                        <div className="row">
                            <div className="col col-md-8 offset-md-2 text-center">
                                <div className="sf-icon-container">
                                    <img src="./sf-icon-1.png" />
                                </div>
                                <p className="passion-container">
                                    Made with
                                    <span>
                                        <HeartFill />
                                    </span>
                                    in SF
                                </p>
                                <div className="mission-statement">
                                    <p>Our mission is to help you ace your coding interviews and land your dream job.</p>
                                    <p>Since inception, we have helped engineers improve coding interview skill and
                                    landed jobs at companies of all size.</p>
                                </div>
                                <div className="company-logo-container">
                                    <img className="google" src="./google-logo.png" />
                                    <img className="amazon" src="./amazon-logo.png" />
                                    <img className="apple" src="./apple-logo.png" />
                                    <img className="facebook" src="./facebook-logo.png" />
                                    <img className="walmart" src="./walmart-logo.png" />
                                    <img className="yahoo" src="./yahoo-logo.png" />
                                    <img className="cisco" src="./cisco-logo.png" />
                                    <img className="hp" src="./hp-logo.png" />
                                    <img className="adp" src="./adp-logo.png" />
                                    <img className="idg" src="./identity-guard-logo.png" />
                                </div>
                                <PrivacyAndTerm withCopyright />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStoreToProps = store => ({
    login: store.login
});

export default connect(mapStoreToProps, { onLoginSubmitted, authQuery })(LandingPage);
