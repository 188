import axios from "axios";
import { API_URL } from "../config";
import { getCookie, setCookie } from "../utils/cookie.util";
import { onLoginSubmitted } from "./login.action";

export const ON_DONE_CHANGING_PROFILE = "ON_DONE_CHANGING_PROFILE";
export const ON_CHANGING_PROFILE = "ON_CHANGING_PROFILE";
export const ON_DONE_CHANGING_PASSWORD = "ON_DONE_CHANGING_PASSWORD";
export const ON_CHANGING_PASSWORD = "ON_CHANGING_PASSWORD";

export const onDoneChangingProfile = values => ({ type: ON_DONE_CHANGING_PROFILE, values });
export const onChangingProfile = values => ({ type: ON_CHANGING_PROFILE, values });
export const onResetChangingProfile = values => ({ type: 'default', values });

export const onDoneChangingPassword = values => ({ type: ON_DONE_CHANGING_PASSWORD, values });
export const onChangingPassword = values => ({ type: ON_CHANGING_PASSWORD, values });
export const onResetChangingPassword = values => ({ type: 'default', values });

const BEARER_KEYWORD = "Bearer ";

export const changeProfileAction = (data = {}) => (
    dispatch => {
        dispatch(onChangingProfile({
            changedProfileMessage: '',
            changedProfileSuccess: false,
            isChangingProfile: true
        }));

        axios.post(`${API_URL}/api/change-profile`, {
            name: data.name,
            email: data.email,
            id: data.id
        }, {
            headers: { Authorization: `${getCookie("authorize")}` }
        })
        .then(res => {
            if (res.data.error) {
                dispatch(onDoneChangingProfile({
                    changedProfileMessage: res.data.error,
                    changedProfileSuccess: false,
                    isChangingProfile: false
                }));
            } else {
                dispatch(onLoginSubmitted({authorize: `${getCookie("authorize")}`, user: res.data.user}));
                dispatch(onDoneChangingProfile({
                    changedProfileMessage: 'Profile has been updated successfully.',
                    changedProfileSuccess: true,
                    isChangingProfile: false
                }));
            }
        }).catch(error => {
            dispatch(onDoneChangingProfile({
                changedProfileMessage: 'Something has gone wrong. Profile change failed.',
                changedProfileSuccess: false,
                isChangingProfile: false
            }));
        });
    }
);

export const changeProfileResetAction = () => (
    dispatch => dispatch(onResetChangingProfile())
);

export const changePasswordAction = (data = {}) => (
    dispatch => {
        dispatch(onChangingPassword({
            changedPasswordMessage: '',
            changedPasswordSuccess: false,
            isChangingPassword: true
        }));

        axios.post(`${API_URL}/api/change-password`, {
        	email: data.email,
        	currentPassword: data.currentPassword,
            newPassword: data.newPassword
        }, {
            headers: { Authorization: `${getCookie("authorize")}` }
        })
        .then(res => {
            if (res.data.error) {
                dispatch(onDoneChangingPassword({
                    changedPasswordMessage: res.data.error,
                    changedPasswordSuccess: false,
                    isChangingPassword: false
                }));
            } else {
                let user = res.data.user;

                // set cookie
                setCookie("authorize", BEARER_KEYWORD + user.webToken, document.domain);

                dispatch(onLoginSubmitted({authorize: `${getCookie("authorize")}`, user: user}));
                dispatch(onDoneChangingPassword({
                    changedPasswordMessage: 'Password has been updated successfully.',
                    changedPasswordSuccess: true,
                    isChangingPassword: false
                }));
            }
        })
        .catch(err => {
            // TBD: handle error here
            dispatch(onDoneChangingPassword({
                changedPasswordMessage: 'Something has gone wrong. Password change failed.',
                changedPasswordSuccess: false,
                isChangingPassword: false
            }));
        });
    }
);

export const changePasswordResetAction = () => (
    dispatch => dispatch(onResetChangingPassword())
);