export function startPrompt(jqconsole, code) {
    jqconsole.Prompt(true, (input) => {
        if (code || input) {
            if (input) {
                let result = "";
                try {
                    result = eval(input); // eslint-disable-line
                } catch (e) {
                    if (e instanceof SyntaxError) {
                        result = "";
                    } else if (e instanceof SyntaxError || e instanceof ReferenceError) {
                        result = `${e.name}: ${e.message || "Syntax Error"}`;
                    }
                }
                if (result || result === 0) {
                    jqconsole.Write(`${result}\n`, "jqconsole-output");
                } else {
                    jqconsole.Write(`${input}\n`, "jqconsole-output");
                }
            } else {
                jqconsole.Write(`${code}\n`, "jqconsole-output");
            }
        }
        startPrompt(jqconsole);
    });
}

export function clearOutput(jqconsole) {
    jqconsole.Clear();
    jqconsole.ClearPromptText();
}
