import { SIGNUP_SUBMITTED, SIGNUP_RESET } from "../actions/signup.action";

const INITIAL_STATE = {
    user: null,
    error: null
};

export function signupReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SIGNUP_SUBMITTED:
        return { ...state, ...action.values };
    case SIGNUP_RESET:
        return { ...state, ...action.values };
    default:
        return state;
    }
}
